import axios from "axios";

export const ALERT_RULES = {
  COMPARE_AMOUNT: {
    name: "CompareAmount",
    label: "Compare Given Amount",
    description:
      "Compare the 'Amount' of a line item between the current and previous invoice",
    columns: ["columnForTextMatch", "columnForComparison"],
  },
  COMPARE_RATE_GIVEN: {
    name: "CompareRateGiven",
    label: "Compare Given Rate",
    description:
      "Compare the 'Rate' given for a line item between the current and previous invoice",
    columns: ["columnForTextMatch", "columnForComparison"],
  },
  COMPARE_RATE_CALCULATED: {
    name: "CompareRateCalculated",
    label: "Compare Calculated Rate",
    description:
      "Calculate the 'Rate' by providing the 'Amount' and 'Quantity' for a line item and compare between the current and previous invoice",
    columns: ["columnForTextMatch", "columnForAmount", "columnForQuantity"],
  },
};

export async function getAlertRules(customerName, haulerName) {
  try {
    const response = await axios.get(
      `/customer-hauler-pair/alert_rules?customerName=${customerName}&haulerName=${haulerName}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching alert rules:", error);
    return [];
  }
}

export async function deleteAlertRule(customerName, haulerName, ruleId) {
  try {
    await axios.delete(
      `/customer-hauler-pair/alert_rules?customerName=${customerName}&haulerName=${haulerName}&rule_id=${ruleId}`
    );
    return true;
  } catch (error) {
    console.error("Error deleting alert rule:", error);
    return false;
  }
}

//To run violations for 1 specific invoice of the customer
export async function runViolationRule(jobId) {
  try {
    if (jobId) {
      const response = await axios.post(`/generate_alerts`, {job_id: jobId});
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error running violation rules', error);
    return null;
  }
}

//To re-run violations for all invoices under a customer-hauler
export async function reRunViolationRule(customerName, haulerName) {
  try {
      const response = await axios.post(`/generate_alerts`, {customer_name: customerName, hauler_name: haulerName});
      return response.data;  
  } catch (error) {
    console.error('Error re-running violation rules', error);
    return null;
  }
}

export async function createAlertRule(customerName, haulerName, rule) {
  // validate rule so that it has required fields
  // {"name": string, "lineItemToMatch": string, "columns": array[string]}
  if (
    !rule.name ||
    !rule.lineItemToMatch ||
    !rule.columns ||
    rule.columns.length === 0
  ) {
    console.error("Invalid rule:", rule);
    return false;
  }

  try {
    await axios.put(
      `/customer-hauler-pair/alert_rules?customerName=${customerName}&haulerName=${haulerName}`,
      rule
    );
    return true;
  } catch (error) {
    console.error("Error creating alert rule:", error);
    return false;
  }
}

export async function updateAlertRule(customerName, haulerName, ruleId, rule) {
  // validate rule so that it has required fields
  // {"name": string, "lineItemToMatch": string, "columns": array[string]}
  if (
    !rule.name ||
    !rule.lineItemToMatch ||
    !rule.columns ||
    rule.columns.length === 0 ||
    !ruleId
  ) {
    console.error("Invalid rule:", rule);
    return false;
  }

  try {
    await axios.post(
      `/customer-hauler-pair/alert_rules?customerName=${customerName}&haulerName=${haulerName}&rule_id=${ruleId}`,
      rule
    );
    return true;
  } catch (error) {
    console.error("Error updating alert rule:", error);
    return false;
  }
}

export function parseFormIntoRules(ruleChoice, formValues) {
  const lineItemToMatch = formValues.lineItemToMatch;
  const columns = [];

  switch (ruleChoice) {
    case ALERT_RULES.COMPARE_AMOUNT.name:
      columns.push(formValues.columnForComparison);
      columns.push(formValues.columnForTextMatch);
      break;
    case ALERT_RULES.COMPARE_RATE_GIVEN.name:
      columns.push(formValues.columnForComparison);
      columns.push(formValues.columnForTextMatch);
      break;
    case ALERT_RULES.COMPARE_RATE_CALCULATED.name:
      columns.push(formValues.columnForQuantity);
      columns.push(formValues.columnForAmount);
      columns.push(formValues.columnForTextMatch);
      break;
    default:
      console.error("Unknown rule choice:", ruleChoice);
      return null;
  }

  return {
    name: ruleChoice,
    lineItemToMatch,
    columns,
  };
}

export function parseRuleIntoForm(rule) {
  const formValues = {
    lineItemToMatch: rule.lineItemToMatch,
  };
  const ruleChoice = rule.name;

  switch (ruleChoice) {
    case ALERT_RULES.COMPARE_AMOUNT.name:
      formValues.columnForComparison = rule.columns[0];
      formValues.columnForTextMatch = rule.columns[1];
      break;
    case ALERT_RULES.COMPARE_RATE_GIVEN.name:
      formValues.columnForComparison = rule.columns[0];
      formValues.columnForTextMatch = rule.columns[1];
      break;
    case ALERT_RULES.COMPARE_RATE_CALCULATED.name:
      formValues.columnForQuantity = rule.columns[0];
      formValues.columnForAmount = rule.columns[1];
      formValues.columnForTextMatch = rule.columns[2];
      break;
    default:
      console.error("Unknown rule choice:", rule.ruleChoice);
      return null;
  }

  return {
    ruleChoice,
    formValues,
  };
}
