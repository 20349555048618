import React, { useState, useEffect } from "react";

import { Skeleton, message, Button, Input, Modal, Form, Space } from "antd";
import {
  CheckCircleTwoTone,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import {
  getCustomerEmail,
  updateCustomerEmail,
  sendEmail,
} from "./InvoiceTableSendEmailProcessing";

export const InvoiceTableSendEmail = () => {
  return (
    <div>
      <h1>InvoiceTableSendEmail</h1>
    </div>
  );
};

export const InvoiceTableSendEmailForm = ({
  customerName,
  haulerName,
  jobId,
  amount,
}) => {
  const [email, setEmail] = useState("");
  const [ccEmails, setCcEmails] = useState([]); // [{email: ""}, {email: ""}
  const [emailLoading, setEmailLoading] = useState(true);
  const [emailSent, setEmailSent] = useState(0); // 0: not sent, 1: sending, 2: done/failed
  const [form] = Form.useForm();

  useEffect(() => {
    // fetch email
    console.log(
      `Fetching customer email for ${customerName} and ${haulerName}`
    );
    getCustomerEmail(customerName, haulerName).then((data) => {
    if (data) {
      setEmail(data.email);
      setCcEmails(data.cc_emails);
      form.setFieldsValue({
        email: data.email,
        cc_emails: data.cc_emails.map((cc_email) => ({ email: cc_email })),
      });
    }

      setEmailLoading(false);
    });
  }, [customerName, haulerName]);

  const onFinish = async (values) => {
    try {
      console.log("onFinish", values);
      const cc_emails = values.cc_emails.map((cc_email) => cc_email.email ? cc_email.email : null).filter((cc_email) => cc_email !== null);

      const updateEmailResult = await updateCustomerEmail(
        customerName,
        haulerName,
        values.email,
        cc_emails
      );

      if (updateEmailResult) {
        message.success("Email updated successfully");
      } else {
        message.error("Failed to update email");
      }

      setEmail(values.email);

      setEmailSent(1);
      const sendEmailResult = await sendEmail(values.email, jobId, amount, cc_emails);

      if (sendEmailResult) {
        message.success("Email sent successfully");
      } else {
        message.error("Failed to send email");
      }
      setEmailSent(0);
    } catch (error) {
      console.error(error);
      message.error("An error occurred");
    }
  };

  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          margin: "auto",
        }}
      >
        <Form
          form={form}
          name="email_form"
          onFinish={onFinish}
          initialValues={{ email }}
        >
          <Form.Item
            name="email"
            label="Customer Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            {emailLoading ? (
              <Skeleton.Input style={{ width: "auto" }} active />
            ) : (
              <Input disabled={emailLoading} placeholder="Enter your email" />
            )}
          </Form.Item>
          <Form.List name="cc_emails" label="CC Email">
            {(fields, {add, remove}) => (
              <>
              {fields.map(({key, name, ...restField}) => (
                <Space key={key} size={10} style={{display: "flex"}} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    label="CC Email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      }
                    ]}
                  >
                    <Input style={{ width: '35vh' }} placeholder="Enter your email" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                >
                  Add CC Email
                </Button>
              </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button loading={emailSent === 1} type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export const InvoiceTableSendEmailModal = ({
  open,
  onCancel,
  customerName,
  haulerName,
  jobId,
  amount,
}) => {
  return (
    <Modal
      title="Send Email"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
    >
      <InvoiceTableSendEmailForm
        customerName={customerName}
        haulerName={haulerName}
        jobId={jobId}
        amount={amount}
      />
    </Modal>
  );
};
