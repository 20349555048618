import React, { useState } from "react";
import { Modal, Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

export const UploadDocumentButton = ({ customerName, haulerName }) => {
    const [visible, setVisible] = useState(false);
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => {
        setVisible(false);
    }

    /** 
     * @type {import("antd").UploadProps} props
    */
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            if (file.type !== 'application/pdf') {
                message.error(`${file.name} is not a pdf file`);
                return Upload.LIST_IGNORE;
            }

            // // Prevent upload
            // return false;
            return true;
        },
        customRequest: ({ file, onSuccess, onError }) => {
            // Get the presigned URL
            axios.get(encodeURI(`/documents/presignedurl?fileName=customer/${customerName}/hauler/${haulerName}/${file.name}&fileType=${file.type}`))
                .then((response) => {
                    let presignedUrl = response.data;

                    const formData = new FormData();
                    formData.append("Content-Type", file.type);
                    Object.entries(presignedUrl.fields).forEach(([k, v]) => {
                        formData.append(k, v);
                    });
                    formData.append("file", file);

                    // Include customerName and haulerName in the upload request
                    formData.append("customerName", customerName);
                    formData.append("haulerName", haulerName);

                    // Use axios to upload the file to the presigned URL
                    axios.post(presignedUrl.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        message.success('File uploaded successfully');
                        onSuccess(response.data);
                    }).catch((error) => {
                        console.log('error =>', error.response.data)
                        message.error(error.message);
                        onError(error);
                    })
                }).catch((error) => {
                    message.error(error.message);
                    onError(error);
                });
        },
        onChange: (info) => {
            setFileList(info.fileList);
        },
        accept: ".pdf",
    };

    return (
        <>
            <Button icon={<UploadOutlined />} onClick={() => setVisible(true)}>
                Upload Document
            </Button>
            <Modal
                open={visible}
                title={`Upload Document for ${customerName} - ${haulerName}`}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={null}
            >
                <Upload {...props} multiple={true}>
                    <Button icon={<UploadOutlined />}>Select Files</Button>
                </Upload>
            </Modal>
        </>
    );
};
