import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Select,
  InputNumber,
  message,
  Form,
  Tooltip,
} from "antd";
import axios from "axios";
import { SavingsInvoiceSettings } from "./SavingsInvoiceSettings";
import { SavingsInvoiceTable } from "./SavingsInvoiceTable";
import { generateSavings } from "./SavingsInvoiceProcessing";

const SavingsInvoiceTypeSelector = ({
  selectedSavingsType,
  onChange,
  onCancel,
}) => {
  return (
    <>
      <Select
        value={selectedSavingsType}
        style={{ width: 200 }}
        onChange={(value) => onChange(value)}
      >
        {selectedSavingsType === "" ? (
          <Select.Option value="">Choose Savings Type</Select.Option>
        ) : (
          <></>
        )}
        <Select.Option value="Flat Fees">Flat Fees</Select.Option>
        <Select.Option value="Standard Fees">Standard Fees</Select.Option>
        {/* Add more options here if needed */}
      </Select>
    </>
  );
};

const SavingsInvoiceFlatFeeSettings = ({ form, handleFlatFeeInput }) => {
  return (
    <>
      <div>
        <Form form={form}>
          <Form.Item
            label="Enter Flat Fees Amount:"
            name="flatFeeAmount"
            rules={[
              { required: true, message: "Please enter the flat fee amount" },
            ]}
          >
            {/* TODO for some reason the intialized value for empty is "Flat Fees" */}
            {/* TODO for some reason this input is allowing alpha characters */}
            <InputNumber
              onChange={(value) =>
                handleFlatFeeInput(value ? value.toString() : "")
              }
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "50%" }}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const SavingsInvoiceStandardFeeSettings = ({
  form,
  handleSavingsPercentageInput,
}) => {
  return (
    <div>
      <Form form={form}>
        <Form.Item
          label="Enter Savings Percentage:"
          name="savingsPercentage"
          rules={[
            { required: true, message: "Please enter the savings percentage" },
          ]}
        >
          <InputNumber
            onChange={(value) =>
              handleSavingsPercentageInput(value ? value.toString() : "")
            }
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
            style={{ width: "50%" }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default function SavingsInvoiceSettingsScreen({
  invoiceTableData,
  columnsFromServer,
  customerName,
  job_id,
  onSaveSettings,
  onCancel,
}) {
  // data
  const [savingsSettings, setSavingsSettings] = useState([]);
  const [flatFeeAmount, setFlatFeeAmount] = useState("");
  const [savingsPercentage, setSavingsPercentage] = useState("");
  const [deletedRows, setDeletedRows] = useState([]);
  const [editDeleteMode, setEditDeleteMode] = useState(false);

  // selections
  const [selectedSavingsType, setSelectedSavingsType] = useState("");

  // enable
  const [enableGenerateButton, setEnableGenerateButton] = useState(false);

  // visibility
  const [processInvoiceModalVisible, setProcessInvoiceModalVisible] =
    useState(false);

  // form
  const [form] = Form.useForm();

  // Getters
  const getFullSavingsSettings = () => {
    return {
      savingsSettings,
      selectedSavingsType,
      flatFeeAmount,
      savingsPercentage,
    };
  };

  // Handlers
  const handleSaveSettingsChange = (recordKey, itemKey, itemValue) => {
    const updatedDataSource = savingsSettings.map((item) => {
      if (item.key === recordKey) {
        return { ...item, [itemKey]: itemValue, updated: true };
      }
      return item;
    });
    setSavingsSettings(updatedDataSource);
  };

  // Define the handleDeleteRow function
  const handleDeleteRow = (rowIndex) => {
    const deletedRow = savingsSettings[rowIndex];
    setDeletedRows((prevState) => [...prevState, deletedRow]);
    const updatedSavingsSettings = savingsSettings.filter(
      (_, index) => index !== rowIndex
    );
    setSavingsSettings(updatedSavingsSettings);
  };

  // Define the handleCancelEditMode function
  const handleCancelEditMode = () => {
    setEditDeleteMode(false);
    setDeletedRows([]);
  };

  const handleAddNewRow = () => {
    const newData = {
      key: crypto.randomUUID(),
      enabled: true,
      name: ``,
      column: "",
      matcheditems: "",
      action: "No action selected",
    };

    // add order, if first the set to 1
    let order =
      savingsSettings.length > 0
        ? Math.max(...savingsSettings.map((o) => o.order || 0)) + 1
        : 1;

    newData.order = order;

    // Check if savingsSettings is null or undefined, and initialize it as an empty array if necessary
    const updatedDataSource = savingsSettings
      ? [...savingsSettings, newData]
      : [newData];

    setSavingsSettings(updatedDataSource);
  };

  const handleSaveSettings = async (generate, applyChanges=false) => {
    if (selectedSavingsType) {
      const data = getFullSavingsSettings();

      let numInvalidActions = 0;
      // Filter out no action selected
      const filteredSavingsSettings = savingsSettings.filter((record) => {
        if (record.action === "No action selected" && record.enabled === true) {
          numInvalidActions++;
          return false;
        } else if (Object.hasOwn(record, "action")) {
          return true;
        } else {
          return true;
        }
      });

      // Remove updated flag
      const updatedSavingsSettings = filteredSavingsSettings.map((record) => {
        const newRecord = { ...record };

        if (generate) {
          delete newRecord.updated;
        }

        return newRecord;
      });

      if (numInvalidActions > 0) {
        message.error("Make sure all items have a selected action");
        return;
      }

      data.savingsSettings = updatedSavingsSettings;

      try {
        const response = await axios.post(
          `/customer/${customerName}/save_settings`,
          data
        );
        if (response.data.message) {
          message.success("Settings saved successfully");
          if (applyChanges) {
            const updatedIds = filteredSavingsSettings.map((record) => {
                if (record?.updated) {
                    return record.key;
                } else {
                    return undefined;
                }}
            ).filter((id) => id !== undefined );

            onSaveSettings(generate, updatedIds);
          } else {
            onSaveSettings(generate);
          }
          onCancel();
        } else {
          console.error("Failed to save settings1:", response.data.error);
        }
      } catch (error) {
        console.error("Failed to save settings2:", error);
      }
    }
  };

  const handleSavingsTypeChange = (value) => {
    // Always update the flatFeeAmount state when the input value changes
    if (value === "Standard Fees") {
      setFlatFeeAmount(flatFeeAmount); // Reset flatFeeAmount to an empty string
      setSavingsPercentage(savingsPercentage);
    } else if (value === "Flat Fees") {
      setFlatFeeAmount(flatFeeAmount); // Set flatFeeAmount to the selected value
      setSavingsPercentage(savingsPercentage);
    }
    setSelectedSavingsType(value);
  };

  const handleFlatFeeInput = (value) => {
    setFlatFeeAmount(value);
  };

  const handleSavingsPercentageInput = (value) => {
    setSavingsPercentage(value);
  };

  // Get current savingsSettings from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/customer/${customerName}/save_settings`
        );

        if (response.data === null) {
          return;
        }

        setSavingsSettings(response.data.savingsSettings);
        setSelectedSavingsType(response.data.selectedSavingsType);
        setFlatFeeAmount(response.data.flatFeeAmount || "");
        setSavingsPercentage(response.data.savingsPercentage || "");

        // Set the flatFeeAmount state with the fetched value
        // TODO not ideal
        if (
          response.data.selectedSavingsType === "Flat Fees" &&
          response.data.flatFeeAmount
        ) {
          setFlatFeeAmount(response.data.flatFeeAmount);
        }

        if (
          response.data.selectedSavingsType === "Standard Fees" &&
          response.data.savingsPercentage
        ) {
          setSavingsPercentage(response.data.savingsPercentage);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [customerName]);

  // This is to set forms...
  useEffect(() => {
    if (selectedSavingsType === "Flat Fees") {
      form.setFieldValue("flatFeeAmount", flatFeeAmount);
    } else if (selectedSavingsType === "Standard Fees") {
      form.setFieldValue("savingsPercentage", savingsPercentage);
    }
  }, [form, flatFeeAmount, savingsPercentage, selectedSavingsType]);

  // conditions need to be met to allow generation
  useEffect(() => {
    let enableButton = true;

    if (!(savingsSettings.length > 0)) {
      enableButton = false;
    }

    if (!selectedSavingsType) {
      enableButton = false;
    }

    if (selectedSavingsType === "Flat Fees" && !flatFeeAmount) {
      enableButton = false;
    }

    if (selectedSavingsType === "Standard Fees" && !savingsPercentage) {
      enableButton = false;
    }

    setEnableGenerateButton(enableButton);
  }, [savingsSettings, selectedSavingsType, flatFeeAmount, savingsPercentage]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "2rem",
        }}
      >
        {editDeleteMode ? (
          <>
            <Button onClick={handleCancelEditMode}>Exit Delete Mode</Button>
          </>
        ) : (
          <Button
            onClick={() => setEditDeleteMode(true)}
            style={{ backgroundColor: "#bf0000", color: "white" }}
          >
            Delete
          </Button>
        )}
      </div>
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "10px",
        }}
      >
        <SavingsInvoiceSettings
          savingsSettings={savingsSettings}
          invoiceColumns={columnsFromServer.map((record) => record.label)}
          handleSavingSettingsChange={handleSaveSettingsChange}
          editDeleteMode={editDeleteMode}
          handleDeleteRow={handleDeleteRow}
          invoiceTableData={invoiceTableData}
        />

        <Button
          onClick={handleAddNewRow}
          style={{
            color: "black",
            backgroundColor: "transparent",
            alignItems: "center",
          }}
        >
          Add New Setting +
        </Button>

        <SavingsInvoiceTypeSelector
          selectedSavingsType={selectedSavingsType}
          onChange={handleSavingsTypeChange}
        />

        {/* Conditional rendering of the Enter Flat Fee form */}
        {selectedSavingsType === "Flat Fees" && (
          <SavingsInvoiceFlatFeeSettings
            form={form}
            handleFlatFeeInput={handleFlatFeeInput}
          />
        )}

        {selectedSavingsType === "Standard Fees" && (
          <SavingsInvoiceStandardFeeSettings
            form={form}
            handleSavingsPercentageInput={handleSavingsPercentageInput}
          />
        )}

        <div style={{ display: "flex", marginTop: "5px", columnGap: "5px" }}>
          <Button
            onClick={() => handleSaveSettings(false)}
            type="primary"
            style={{ height: "40px", width: "150px" }}
          >
            Save
          </Button>
          <Tooltip title="This will only update the rows that are highlighted">
            <Button
              onClick={() => handleSaveSettings(true, true)}
              type="primary"
              style={{ height: "40px", width: "150px" }}
            >
              Save & Apply
            </Button>
          </Tooltip>

          <Tooltip title="Warning, this will overwrite any manual changes.">
            <Button
              onClick={() => handleSaveSettings(true)}
              type="primary"
              style={{ height: "40px", width: "150px" }}
            >
              Save & Generate
            </Button>
          </Tooltip>
          <Button
            onClick={() => setProcessInvoiceModalVisible(true)}
            type={"primary"}
            style={{ height: "40px", width: "150px" }}
            disabled={!enableGenerateButton}
          >
            Preview
          </Button>
        </div>

        <Modal
          title="Processed Savings Invoice"
          open={processInvoiceModalVisible}
          size="medium"
          width={800}
          onOk={() => setProcessInvoiceModalVisible(false)}
          onCancel={() => setProcessInvoiceModalVisible(false)}
        >
          {enableGenerateButton ? (
            <SavingsInvoiceTable
              data={generateSavings(
                {
                  savingsSettings,
                  selectedSavingsType,
                  flatFeeAmount,
                  savingsPercentage,
                },
                invoiceTableData.nodes,
                "Test"
              )}
            />
          ) : (
            <></>
          )}
        </Modal>
      </div>
    </>
  );
}
