import React, { useState, useEffect } from 'react';
import { SavingsInvoiceTable } from '../../Components/SavingsInvoice/SavingsInvoiceTable';
import { v4 as uuidv4 } from 'uuid';

const data = {
    savingsInvoice: [
      {
        description: "Pickup Service",
        quantity: 1,
        baselineCost: 2288.81,
        approvedCost: 2288.81,
        savings: 0,
        dFees: 0,
      },
      {
        description: "123",
        quantity: undefined,
        baselineCost: 0,
        approvedCost: 0,
        savings: 0,
        dFees: 0,
      },
      {
        description: "NewItem",
        quantity: undefined,
        baselineCost: 0,
        approvedCost: 0,
        savings: 0,
        dFees: 0,
      },
      {
        description: "Consulting/Maintenance Fee",
        quantity: 1,
        baselineCost: 0,
        approvedCost: 0,
        savings: 0,
        dFees: 120,
      },
      {
        description: "Total",
        quantity: undefined,
        baselineCost: 2288.81,
        approvedCost: 2288.81,
        savings: 0,
        dFees: 120,
      },
    ],
    timestamp: 1713993179000,
    invoice_num: 12,
  }

// add a unique id, UUID
const addId = (data) => {
    return data.map((item, index) => {
        return { ...item, id: uuidv4() }
    });
}


export const EditableSavingsInvoice = () => {
    const [invoiceData, setInvoiceData] = useState(addId(data.savingsInvoice));
    const [prevInvoiceData, setPrevInvoiceData] = useState([]);

    const onSave = (newInvoiceData) => {
        console.log("Save");
        console.log(newInvoiceData);
        setPrevInvoiceData(invoiceData);
        setInvoiceData(newInvoiceData);
    }


    return (
        <div>
            <SavingsInvoiceTable data={invoiceData} editable={true} onSave={onSave} />
            
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <h3>Previous Invoice Data</h3>
                    <pre>{JSON.stringify(prevInvoiceData, null, 2)}</pre>
                </div>

                <div style={{ flex: 1 }}>
                    <h3>Current Invoice Data</h3>
                    <pre>{JSON.stringify(invoiceData, null, 2)}</pre>
                </div>
            </div>

        </div>
    );
}