import React, { useState, useEffect } from "react";
import { Button, Modal, Input, List, Typography } from "antd";
import axios from "axios";

const { TextArea } = Input;
const { Text } = Typography;

const commentSectionStyle = {
  paddingLeft: "10px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  minHeight: "50px",
  minWidth: "300px",
};

const commentListStyle = {
  maxHeight: "800px",
  overflowY: "auto",
};

const commentItemStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const CustomerComments = (props) => {
  const [comments, setComments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedComment, setEditedComment] = useState("");
  const [latestComment, setLatestComment] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const fetchLatestComments = async () => {
    try {
      const cname = props.customerName;
      const hname = props.haulerName;
      const response = await axios.get(
        `/customer-hauler-pair/latest-comment?customerName=${cname}&haulerName=${hname}`
      );
      setLatestComment(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  useEffect(() => {
    fetchLatestComments();
  }, [props.customerName, props.haulerName]);

  const handleAddComment = () => {
    setShowAddModal(true);
  };

  const handleSaveComment = () => {
    if (newComment.trim() !== "") {
      const timestamp = new Date().toLocaleString();
      const comment = { comment: newComment, timestamp };

      // Access customerName and haulerName from the customerName object
      const cname = props.customerName;
      const hname = props.haulerName;

      axios
        .post(
          `/customer-hauler-pair/comment?customerName=${cname}&haulerName=${hname}`,
          comment
        )
        .then((response) => {
          // Update comments state with the new comment
          setComments([comment, ...comments]);
          setNewComment("");
          setShowAddModal(false);
          // Fetch the latest comment after adding a new comment
          fetchLatestComments();
        })
        .catch((error) => {
          // Handle error
          console.error("Failed to save comment:", error);
        });
    }
  };

  const handleShowHistory = () => {
    const cname = props.customerName;
    const hname = props.haulerName;

    const fetchAllComments = async () => {
      try {
        const response = await axios.get(
          `/customer-hauler-pair/comment?customerName=${cname}&haulerName=${hname}`
        );
        // Sort the comments based on the created_at field in descending order
        const sortedComments = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setComments(sortedComments);
        setShowHistoryModal(true);
      } catch (error) {
        console.error("Failed to fetch comments:", error);
      }
    };

    fetchAllComments();
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setShowHistoryModal(false);
  };

  const handleEditComment = (index) => {
    setEditingIndex(index);
    const currentComment = comments[index].comment;
    setEditedComment(currentComment);
    setIsEditing(true);
  };

  const handleEditChange = (event) => {
    const editedCommentText = event.target.value;
    setEditedComment(editedCommentText);
  };

  const handleEditBlur = () => {
    if (isEditing) {
      setIsEditing(false);
    }
  };

  const handleSaveEditedComment = (commentId) => {
    const cname = props.customerName;
    const hname = props.haulerName;
    const editedCommentText = editedComment.trim();
    if (editedCommentText !== "") {
      axios
        .put(
          `/customer-hauler-pair/comment?customerName=${cname}&haulerName=${hname}`,
          {
            comment_id: commentId,
            comment: editedCommentText,
          }
        )
        .then((response) => {
          const updatedComments = comments.map((comment) =>
            comment.comment_id === commentId
              ? { ...comment, comment: editedCommentText }
              : comment
          );
          setComments(updatedComments);
          setEditingIndex(null);
          setEditedComment("");
          fetchLatestComments();
        })
        .catch((error) => {
          console.error("Failed to save edited comment:", error);
        });
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedComment("");
  };

  const handleDeleteComment = (comment_id) => {
    setCommentToDelete(comment_id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDeleteComment = () => {
    const cname = props.customerName;
    const hname = props.haulerName;

    axios
      .delete(
        `/customer-hauler-pair/comment?customerName=${cname}&haulerName=${hname}`,
        {
          data: {
            comment_id: commentToDelete,
          },
        }
      )
      .then(() => {
        const updatedComments = comments.filter(
          (comment) => comment.comment_id !== commentToDelete
        );
        setComments(updatedComments);
        setShowDeleteConfirmation(false);
        setCommentToDelete(null);
        fetchLatestComments();
      })
      .catch((error) => {
        console.error("Failed to delete comment:", error);
        setShowDeleteConfirmation(false);
        setCommentToDelete(null);
      });
  };

  const handleCancelDeleteComment = () => {
    setShowDeleteConfirmation(false);
    setCommentToDelete(null);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: "auto",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Customer Comment
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={handleShowHistory}
              style={{ fontSize: "10px" }}
            >
              See All Comments
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={handleAddComment}
              style={{ fontSize: "10px", marginLeft: "10px" }}
            >
              Add Comment
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "500px",
            height: "auto",
            display: "flex",
            ...commentSectionStyle,
          }}
        >
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            {latestComment?.comment ? (
              <Text>{latestComment.comment}</Text>
            ) : (
              // make the text here faded
              <Text type="secondary">No comments available, click "Add Comment" to save a message.</Text>
            )}
          </div>
        </div>
      </div>
      <Modal
        title="Add Comment"
        open={showAddModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSaveComment}>
            Save
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          autoSize={{ minRows: 4, maxRows: 6 }} // Dynamically resize the textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Type here..."
        />
      </Modal>
      <Modal
        title="Comment History"
        open={showHistoryModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
        width={600}
      >
        <div style={{ height: "300px", overflowY: "auto" }}>
          <List
            dataSource={comments}
            renderItem={(comment, index) => (
              <div
                style={{
                  marginBottom: "10px",
                  position: "relative",
                  marginRight: "5px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    paddingBottom: "2rem",
                  }}
                >
                  {editingIndex === index ? (
                    <TextArea
                      value={editedComment}
                      onChange={handleEditChange}
                      onBlur={handleEditBlur}
                      autoFocus // Focus on the textarea when editing starts
                    />
                  ) : (
                    <Text>{comment.comment}</Text>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: ".25rem",
                  }}
                >
                  {/* Displaying updated_at timestamp if available, otherwise, display created_at timestamp */}
                  <Text type="secondary">
                    {comment.updated_at ? 
                      `Updated at ${new Date(comment.updated_at).toLocaleString()}` :
                      `Created at ${new Date(comment.created_at).toLocaleString()}`}
                  </Text>
                  <div>
                    {editingIndex === index ? (
                      <>
                        <Button
                          type="link"
                          onClick={() =>
                            handleSaveEditedComment(comment.comment_id)
                          }
                        >
                          Save
                        </Button>
                        <Button type="link" onClick={() => handleCancelEdit()}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="link"
                          onClick={() => handleEditComment(index)}
                        >
                          Edit
                        </Button>
                        <Button
                          type="link"
                          danger
                          onClick={() =>
                            handleDeleteComment(comment.comment_id)
                          }
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            style={commentListStyle}
          />
        </div>
      </Modal>
      <Modal
        title="Are you sure you want to delete this comment?"
        open={showDeleteConfirmation}
        onOk={handleConfirmDeleteComment}
        onCancel={handleCancelDeleteComment}
      ></Modal>
    </>
  );
};

export default CustomerComments;
