import React, { useState } from "react";
import { List, Button, Modal } from "antd";
import { EditableInvoiceName } from "./SavingsInvoiceHistoryEditableInvoiceName";
import { SavingsInvoiceTable } from "./SavingsInvoiceTable";

export const SavingsInvoiceHistoryScreen = ({ history, jobId, onSaveSuccess }) => {
  // SelectionStates
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  // Visible States
  const [savingsInvoiceVisible, setSavingsInvoiceVisible] = useState(false);

  // Handlers
  const handleShowInvoice = (item) => {
    setSelectedInvoice(item);
    setSavingsInvoiceVisible(true);
  };

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "10px",
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={history}
        style={{ width: "100%" }}
        pagination={{
          pageSize: 5,
        }}
        renderItem={(item, _) => (
          <List.Item>
            <List.Item.Meta
              title={
                <EditableInvoiceName
                  title={item.invoice_name || `Invoice ${item.invoice_num}`}
                  invoiceNum={item.invoice_num}
                  jobId ={jobId}
                  timestamp={item.timestamp}
                  onSaveSuccess={onSaveSuccess}
                />
              }
              description={`Generated on ${new Date(item.timestamp).toLocaleString()}`}
            />
            <Button type="primary" onClick={() => handleShowInvoice(item)}>
              View
            </Button>
          </List.Item>
        )}
      />
      <Modal
        title={`Savings Invoice generated on ${
          selectedInvoice
            ? new Date(selectedInvoice.timestamp).toLocaleString()
            : ""
        }`}
        open={savingsInvoiceVisible}
        width={"90%"}
        onOk={() => setSavingsInvoiceVisible(false)}
        onCancel={() => setSavingsInvoiceVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        {selectedInvoice ? (
          <SavingsInvoiceTable data={selectedInvoice.savingsInvoice} />
        ) : null}
      </Modal>
    </div>
  );
};
