import React from 'react';
import { SavingsInvoicePDF } from '../../Components/SavingsInvoice/SavingsInvoicePDF';

const data = [
      {
        "date": "2021-07-01",
        "description": "Pickup Service",
        "quantity": 1,
        "baselineCost": 2288.81,
        "approvedCost": 2288.81,
        "savings": 0,
        "dFees": 0
      },
      {
        "description": "123",
        "baselineCost": 0,
        "approvedCost": 0,
        "savings": 0,
        "dFees": 0
      },
      {
        "description": "NewItem",
        "baselineCost": 0,
        "approvedCost": 0,
        "savings": 0,
        "dFees": 0
      },
      {
        "description": "Consulting/Maintenance Fee",
        "quantity": 1,
        "baselineCost": 0,
        "approvedCost": 0,
        "savings": 0,
        "dFees": 120
      },
      {
        "description": "Total",
        "baselineCost": 2288.81,
        "approvedCost": 2288.81,
        "savings": 0,
        "dFees": 120
      }
    ]



export const InvoiceRenderPDFPage = () => {
    return (
        <div>
            <SavingsInvoicePDF invoiceData={data} preview={true} />
        </div>
    );
}