import React, { useState } from 'react';
import { Typography, Input, Tooltip, Button } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Text } = Typography;

export const EditableInvoiceName = ({ title, jobId, timestamp, onSaveSuccess }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedInvoiceName, setEditedInvoiceName] = useState(title);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      const timestampDate = new Date(timestamp);
      const convertedTimestamp = formatDate(timestampDate);
      console.log('convertedTimestamp', convertedTimestamp);
      await axios.put(`/savings?id=${jobId}&invoice_name=${editedInvoiceName}&created_at=${convertedTimestamp}`);
      setEditMode(false);
      // Callback to notify SavingsInvoice of successful save
      onSaveSuccess();
    } catch (error) {
      console.error('Error updating invoice title:', error);
    }
  };

  const handleCancel = () => {
    setEditedInvoiceName(title);
    setEditMode(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {editMode ? (
        <>
          <Input
            value={editedInvoiceName}
            onChange={(e) => setEditedInvoiceName(e.target.value)}
            onPressEnter={handleSave}
            style={{ width: '200px' }}
          />
          <Button type="primary" onClick={handleSave} style={{ marginLeft: 8 }}>
            <SaveOutlined />
          </Button>
          <Button type="default" onClick={handleCancel} style={{ marginLeft: 8 }}>
            <CloseOutlined />
          </Button>
        </>
      ) : (
        <Text style={{ marginRight: 8 }}>{title}</Text>
      )}
      {!editMode && (
        <Tooltip title="Edit Title">
          <EditOutlined onClick={handleEditClick} />
        </Tooltip>
      )}
    </div>
  );
};