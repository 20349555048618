import React, { useState, useEffect } from "react";

import axios from "axios";

import { Spin, Button, Modal, Typography, message, Input, Popover } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { SavingsInvoiceTable } from "./SavingsInvoiceTable";
import SavingsInvoiceSettingsScreen from "./SavingsInvoiceSettingsScreen";
import { SavingsInvoiceHistoryScreen } from "./SavingsInvoiceHistoryScreen";
import {
  generateNewSavingsInvoice,
  checkSavedSettingsExists,
  saveSavingsInvoice,
  applyOnlyUpdatedSavingsInvoice 
} from "./SavingsInvoiceProcessing";
import {
    SavingsInvoicePDFDownload
} from "./SavingsInvoicePDF";

import { v4 as uuidv4 } from 'uuid';


const RowActionButtons = ({ rowId, onDeleteRow }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Button
        size="small"
        type="primary"
        danger
        icon={<DeleteOutlined />}
        onClick={() => onDeleteRow(rowId)}
      />
    </div>
  );
};

export const SavingsInvoice = ({
  invoiceData,
  invoiceColumns,
  customerName,
  jobId,
}) => {
  // data states
  const [history, setHistory] = useState([]);

  // Selection States
  const [selectedInvoice, setSelectedInvoice] = useState({});

  // Loading States
  const [isLoading, setLoading] = useState(true);

  // Visibile States
  const [savingsHistoryVisible, setSavingsHistoryVisible] = useState(false);
  const [savingsSettingsVisible, setSavingsSettingsVisible] = useState(false);

   // signal
   const [resetSignal, setResetSignal] = useState(0);

  // use as a marker to allow the component to recall the get table api
  const [resetApiMarker, setResetApiMarker] = useState(-1);

  const getSavingsInvoiceHistory = async () => {
    try {
      const response = await axios.get(`/savings?id=${jobId}`);

      /**
       * @typedef {Object} SavingsHistory
       * @property {import("./SavingsInvoiceProcessing").SavingsLineItem[]} [key] - an index signature indicating any property name with values of any type.
       */

      /** @type {SavingsHistory} */
      const body = response.data;

      /*
                    Expecting the data to come in as {
                        timestamp: SavingsInvoiceLineItems[]
                    }
    
                    Need to transform into a sorted list
                */

      const parsedHistory = body.map(item => ({
        timestamp: Date.parse(item.created_at),
        invoice_name: item.invoice_name,
        savingsInvoice: item.invoice_data.map(value => ({
          id: value?.id ? value.id : uuidv4(),
          settingKey: value?.settingKey,
          date: value.date,
          description: value.description,
          quantity: value.quantity ? parseInt(value.quantity) : undefined,
          tonnage: value.tonnage ? parseFloat(value.tonnage) : undefined,
          baselineCost: parseFloat(value.baselineCost),
          approvedCost: parseFloat(value.approvedCost),
          savings: parseFloat(value.savings),
          dFees: parseFloat(value.dFees),
        })),
      }));
  
      const sortedHistory = parsedHistory.sort((a, b) => b.timestamp - a.timestamp);
      sortedHistory.forEach((item, index) => {
        item.invoice_num = sortedHistory.length - index;
      });

      setHistory(sortedHistory);
      setSelectedInvoice(sortedHistory[0] || {});
    } catch (error) {
      console.error("Error fetching savings invoice history:", error);
    }
    setLoading(false);
  };

  /**
   * Helper: gets the invoice number from the selected invoice
   * @returns { number | undefined }
   */
  const getInvoiceNumFromSelectedInvoice = () => {
    return selectedInvoice.invoice_num;
  };

  const handleGenerateInvoice = async (generate, updateRowIds=null) => {
    if (!generate) {
        return;
    }

    const savedSettings = await checkSavedSettingsExists(customerName);
    if (!savedSettings) {
      console.log("No saved settings found");
      return;
    }

    setLoading(true);

    if (updateRowIds) {
        await applyOnlyUpdatedSavingsInvoice(
            jobId,
            customerName,
            invoiceData.nodes,
            getInvoiceNumFromSelectedInvoice,
            selectedInvoice.savingsInvoice,
            updateRowIds
        );
    } else {
        await generateNewSavingsInvoice(jobId, customerName, invoiceData.nodes, getInvoiceNumFromSelectedInvoice);
    }

    try {
      getSavingsInvoiceHistory();
    } catch (error) {
      console.error("Error generating savings invoice:", error);
    }
  };

  useEffect(() => {
    // on first component load
    getSavingsInvoiceHistory();
  }, []);

  const refreshHistory = async () => {
    try {
      await getSavingsInvoiceHistory();
    } catch (error) {
      console.error("Error refreshing savings invoice history:", error);
    }
  };

  const handleSavingsInvoiceEditingSave = async (newData) => {
      setLoading(true);

      // save new invoice data
      await saveSavingsInvoice(jobId, newData, getInvoiceNumFromSelectedInvoice);

      // load the data
      await getSavingsInvoiceHistory()
    };


  // For debugging purposes
  useEffect(() => {
    return
  }, [selectedInvoice]);

  const renderMainContent = () => {
    return (
      <>
        <SavingsInvoiceTable
          data={selectedInvoice?.savingsInvoice || []}
          renderCell={(rowIndex, column, value) =>
            renderCell(rowIndex, column, value, editMode)
          }
          editable={true}
          onSave={handleSavingsInvoiceEditingSave}
          columns={[
            ...invoiceColumns,
            {
              title: "", // Add a title for the delete icon column
              dataIndex: "actions", // Unique key for the delete icon column
              render: (_, record, rowIndex) => (
                <RowActionButtons
                  rowIndex={rowIndex}
                  onDeleteRow={handleDeleteRow}
                />
              ),
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", columnGap: "10px", width: "100%" }}>
          <Button type="primary" onClick={() => setSavingsSettingsVisible(true)}>
            View Settings
          </Button>
          <Button
            type="primary"
            >
            <SavingsInvoicePDFDownload invoiceData={selectedInvoice?.savingsInvoice}  pdfName={`${customerName}_${Date.now().toString()}`}/>

          </Button>
          <Button
            type="primary"
            disabled={history.length < 1}
            onClick={() => setSavingsHistoryVisible(true)}
          >
            View History
          </Button>
        </div>
      </>
    );
  };

  const renderModalContent = () => {
    return (
      <>
        <Modal
          title="Savings Invoice Settings"
          open={savingsSettingsVisible}
          width={"90%"}
          onOk={() => setSavingsSettingsVisible(false)}
          onCancel={() => setSavingsSettingsVisible(false)}
          destroyOnClose={true}
          footer={null}
        >
          <SavingsInvoiceSettingsScreen
            invoiceTableData={invoiceData}
            columnsFromServer={invoiceColumns}
            customerName={customerName}
            job_id={jobId}
            onSaveSettings={handleGenerateInvoice}
            onCancel={() => setSavingsSettingsVisible(false)}
          />
        </Modal>

        <Modal
          title={`Savings Invoice generated on ${
            selectedInvoice
              ? new Date(selectedInvoice.timestamp).toLocaleString()
              : ""
          }`}
          open={savingsHistoryVisible}
          width={"90%"}
          onOk={() => setSavingsHistoryVisible(false)}
          onCancel={() => setSavingsHistoryVisible(false)}
          destroyOnClose={true}
          footer={null}
        >
          <SavingsInvoiceHistoryScreen history={history} jobId={jobId} onSaveSuccess={refreshHistory} />
        </Modal>
      </>
    );
  };

  const renderFirstTimeGenerateInvoice = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "10px",
        }}
      >
        <ExclamationCircleOutlined
          style={{
            fontSize: "10rem",
            color: "grey",
          }}
        />
        <Typography.Title
          level={4}
          style={{
            color: "grey",
          }}
        >
          No Savings Invoice Found
        </Typography.Title>
        <Typography.Text
          style={{
            color: "grey",
          }}
        >
          Click the button below to generate a new invoice
        </Typography.Text>
        <Button type="primary" onClick={() => setSavingsSettingsVisible(true)}>
          View Settings
        </Button>
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        margin: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "10px",
      }}
    >
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          {history.length > 0 && selectedInvoice
            ? renderMainContent()
            : renderFirstTimeGenerateInvoice()}
        </>
      )}

      {renderModalContent()}
    </div>
  );
};
