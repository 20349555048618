import axios from "axios";

export const GROUP_ADD_NO_SAVINGS = "Group together & add to baseline cost";
export const GROUP_ADD_SAVINGS = "Group & compare baseline";
export const ADD_FLAT_FEE = "Add flat fee savings";
export const ADD_STANDARD_FEE = "Add Standard fee savings";

export const SAVINGS_ACTIONS = [
  GROUP_ADD_NO_SAVINGS,
  GROUP_ADD_SAVINGS,
  ADD_FLAT_FEE,
  ADD_STANDARD_FEE
];

/**
 * @typedef SavingsLineItem
 * @type {object}
 * @property {string} settingKey
 * @property {string} description
 * @property {number|undefined} quantity
 * @property {number|undefined} tonnage
 * @property {number} baselineCost
 * @property {number} approvedCost
 * @property {number} savings
 * @property {number} dFees
 */

/**
 * Performs an action based on the provided action and data.
 *
 * @param {string} action - The action to be performed.
 * @param {any} data - The data required for the action.
 * @param {string} name - Name of the line item
 * @returns {SavingsLineItem} - An object representing the result of the action.
 */
function performAction(action, data, name, setting, fullSettings) {

  const lineItem = {
    settingKey: setting?.key,
    description: name,
    quantity: 0,
    tonnage: 0,
    baselineCost: 0,
    approvedCost: 0,
    savings: 0,
    dFees: 0,
  };

  switch (action) {
    case GROUP_ADD_NO_SAVINGS:
      data.forEach((o) => {
        // First, try to get the quantity from the 'Quantity' column
        let quantity = parseInt(o.Quantity) || 0;

        // If quantity is not found or is 0, check the 'description_2' column
        if (quantity === 0 && o.hasOwnProperty('Description_2') && typeof o.Description_2 === 'string') {
          const match = o.Description_2.match(/(\d+)\s*Each\s*@/);
          if (match) {
            quantity = parseInt(match[1]);
          }
        }
        // Use the extracted quantity value
        lineItem.quantity += quantity || 1; // If quantity is still not found or is 0, assume it as 1

        const amount = parseAmount(o.Amount) || 0;
        lineItem.baselineCost += amount;
        lineItem.approvedCost += amount;
      });

      lineItem.savings = lineItem.baselineCost - lineItem.approvedCost;
      break;

    case GROUP_ADD_SAVINGS:
      data.forEach((o) => {
        // First, try to get the quantity from the 'Quantity' column
        let quantity = parseInt(o.Quantity) || 0;

        // If quantity is not found or is 0, check the 'description_2' column
        if (quantity === 0 && o.hasOwnProperty('Description_2') && typeof o.Description_2 === 'string') {
          const match = o.Description_2.match(/(\d+)\s*Each\s*@/);
          if (match) {
            quantity = parseInt(match[1]);
          }
        }
        // Use the extracted quantity value
        lineItem.quantity += quantity || 1; // If quantity is still not found or is 0, assume it as 1

        const amount = parseAmount(o.Amount) || 0;
        lineItem.baselineCost += amount;
        lineItem.approvedCost += amount;
      });

      const baselineCostFromSettings = setting.baselinecost || 0;
      lineItem.baselineCost = parseFloat(baselineCostFromSettings);

      lineItem.savings = lineItem.baselineCost - lineItem.approvedCost;

      const savingsPercentageString = fullSettings.savingsPercentage || '0';
      const savingsPercentage = parseFloat(savingsPercentageString) / 100; // Convert percentage to decimal
      lineItem.dFees = lineItem.savings * savingsPercentage;
      break;
    
    case ADD_FLAT_FEE:
      lineItem.quantity = 1;
      lineItem.dFees = parseFloat(data);
      break;
    case ADD_STANDARD_FEE:
      const baselineCostFromSettingsForStandardFee = fullSettings.baselineCost || 0;
      lineItem.baselineCost = parseFloat(baselineCostFromSettingsForStandardFee);
      break;
    default:
      break;
  }

  return lineItem;
}

// Helper function to parse the amount and handle negative values
function parseAmount(amountString) {
  if (amountString && typeof amountString === 'string') {
    // Check if the string is enclosed in parentheses
    if (amountString.startsWith('(') && amountString.endsWith(')')) {
      // Remove the parentheses and negate the value
      return -parseFloat(amountString.slice(1, -1));
    }
  }
  // If not a string or not enclosed in parentheses, simply parse the value
  return parseFloat(amountString) || 0;
}
/**
 *
 * @param {{
 *  savingsSettings: any[],
 *  selectedSavingsType: string,
 *  flatFeeAmount: string|undefined
 *  baselineCost: string|undefined
 * }} fullSettings
 * @param {any[]} data
 * @param {string} jobId
 *
 * @returns {SavingsLineItem[]} - list of savings line items
 */
export function generateSavings(fullSettings, data, jobId) {
  /**
   * @type {SavingsLineItem[]}
   */
  const processedData = [];

  fullSettings.savingsSettings.forEach((setting) => {
    if (!setting.enabled) return;

    if (setting.action === ADD_FLAT_FEE) return;
    if (setting.action === ADD_STANDARD_FEE) return;
    /*
            action -> what processing step we will do
            column -> the target column that we will be searching for matches
            matchedItems -> the value that we will compare the target column against
            name -> the name of the final line item that will appear in the savings invoice

            args -> a dictionary of additional arguments
        */
    const { action, column, matcheditems, name, baselinecost } = setting;

    // Find all relevant rows first
    const matchedRows = data.filter((row) => {
      if (!row) return false;

      if (!row.hasOwnProperty(column)) {
        return false;
      }

      const regex = new RegExp(matcheditems, "i");
      return regex.test(row[column]);
    });
    processedData.push(performAction(action, matchedRows, name, setting, fullSettings));   
  });

  // optional process if flat fee
  if (fullSettings.selectedSavingsType == "Flat Fees") {
    processedData.push(
      performAction(
        ADD_FLAT_FEE,
        fullSettings.flatFeeAmount,
        "Consulting/Maintenance Fee"
      )
    );
  }

  // get totals
  processedData.push(
    processedData.reduce(
      (acc, curr) => {
        acc.baselineCost += curr.baselineCost;
        acc.approvedCost += curr.approvedCost;
        acc.savings += curr.savings;
        acc.dFees += curr.dFees;
        return acc;
      },
      {
        description: "Total",
        baselineCost: 0,
        approvedCost: 0,
        savings: 0,
        dFees: 0,
      }
    )
  );

  return processedData;
}

let currentInvoiceNumber = 1;

export async function generateNewSavingsInvoice(
  jobId,
  customerName,
  invoiceData,
  getInvoiceNum
) {
  const fullSettings = (
    await axios.get(`/customer/${customerName}/save_settings`)
  ).data;

  const processedData = generateSavings(fullSettings, invoiceData, jobId);
  const invoiceNum = (typeof getInvoiceNum() === 'number' ? getInvoiceNum() : 0) + 1; //handles the case where invoice number returns undefined or non-numeric value
  const invoice_name = `Invoice ${invoiceNum}`;

  try {
    await axios.post(`/savings?id=${jobId}&invoice_name=${invoice_name}`, processedData);
  } catch (error) {
    console.error("Error sending processed invoice data:", error);
  }
  currentInvoiceNumber++;
}

export async function applyOnlyUpdatedSavingsInvoice(
    jobId,
    customerName,
    invoiceData,
    getInvoiceNum,
    currentSavingsInvoiceData,
    updateRowIds
  ) {
    const fullSettings = (
      await axios.get(`/customer/${customerName}/save_settings`)
    ).data;

    const processedData = generateSavings(fullSettings, invoiceData, jobId);

    // From processedData, only take the line items specified in updateRowIds
    const updatedData = processedData.filter((item, _) => updateRowIds.includes(item.settingKey));

    // Replace the line items in currentSavingsInvoiceData with the updated line items
    const updatedSavingsInvoiceData = currentSavingsInvoiceData.map((item) => {
        const updatedItem = updatedData.find((updatedItem) => updatedItem.settingKey === item.settingKey);

        if (updatedItem) {
          const copyUpdatedItem = { ...updatedItem };
          updatedItem.found = true
          return copyUpdatedItem;
        } else {
            return item;
        }
    });

    // Search for updatedItems that have not been matched, and add them to the end of the list
    updatedData.forEach((updatedItem) => {
        if (!updatedItem?.found) {
            // insert one before the end
            updatedSavingsInvoiceData.splice(updatedSavingsInvoiceData.length - 1, 0, updatedItem);
        }
    });

    const invoiceNum = (typeof getInvoiceNum() === 'number' ? getInvoiceNum() : 0) + 1; //handles the case where invoice number returns undefined or non-numeric value
    const invoice_name = `Invoice ${invoiceNum}`;
  
    try {
      await axios.post(`/savings?id=${jobId}&invoice_name=${invoice_name}`, updatedSavingsInvoiceData);
    } catch (error) {
      console.error("Error sending processed invoice data:", error);
    }
  }

export async function saveSavingsInvoice(
    jobId,
    newSavingsInvoiceData,
    getInvoiceNum
  ) {
    const invoiceNum = (typeof getInvoiceNum() === 'number' ? getInvoiceNum() : 0) + 1; //handles the case where invoice number returns undefined or non-numeric value
    const invoice_name = `Invoice ${invoiceNum}`;
  
    try {
      await axios.post(`/savings?id=${jobId}&invoice_name=${invoice_name}`, newSavingsInvoiceData);
    } catch (error) {
      console.error("Error sending processed invoice data:", error);
    }
  }

export async function checkSavedSettingsExists(customerName) {
    try {
      const response = await axios.get(`/customer/${customerName}/save_settings`);
      return response.data !== null;
    } catch (error) {
      console.error("Error fetching saved settings:", error);
      return false;
    }
}
