import React from "react";
import { InvoiceTableSendEmailForm } from "../../Components/InvoiceTable/InvoiceTableSendEmail";

export const EmailForm = () => {
  return (
    <div>
      <InvoiceTableSendEmailForm
        customerName={"Bryan Marquez"}
        haulerName={"RepublicServices"}
        jobId={
          "685a625f53c52aad8f9df1ca871ba5ae50569720d4bff976b875610fd2c4a471"
        }
        amount={300}
      />
    </div>
  );
};
