import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./Components/Header";
import Login, { fakeAuth } from "./Components/Login";
import YTable from "./Pages/Dashboard";
import InvoiceTable from "./Pages/InvoiceTable";
import "./scss/style.scss";
import LandingPage from "./Pages/LandingPage";
import CustomerPage from "./Pages/CustomerPage";
import HaulerPage from "./Pages/HaulerPage";


// Testing components
import { InvoiceRenderPDFPage } from "./Pages/test/InvoiceRenderPDFPage";
import { EditableSavingsInvoice } from "./Pages/test/EditableSavingsInvoice";
import { AlertRulesTest } from "./Pages/test/AlertRules";
import { EmailForm } from "./Pages/test/EmailForm";

import { ConfigProvider } from "antd";

export default function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
          },
          components: {
            DatePicker: {
                activeBg: "#ffffff",
                cellActiveWithRangeBg: "#ffffff",
            }
          }
        }}
      >
        <Header />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" exact component={LandingPage} />
          <Route
            path="/customer/:customerName"
            exact
            component={CustomerPage}
          />
          <Route path="/dashboard" exact component={YTable} />
          <Route
            path="/customer/:customerName/:haulerName/invoice/:job_id"
            component={InvoiceTable}
          />
          <Route path="/customer/:customerName/hauler" component={HaulerPage} />
          <Route
            path="/test/InvoiceRenderPDFPage"
            component={InvoiceRenderPDFPage}
          />
          <Route
            path="/test/EditableSavingsInvoice"
            component={EditableSavingsInvoice}
          />
          <Route
            path="/test/AlertRulesBox"
            component={AlertRulesTest}
          />
          <Route
            path="/test/EmailForm"
            component={EmailForm}
          />
        </Switch>
      </ConfigProvider>
    </>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        fakeAuth.isAuthenticated === true ? (
          //https://reactjs.org/docs/jsx-in-depth.html#spread-attributes
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
