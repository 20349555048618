import React, { useState, useEffect } from "react";

import { List, message, Button, Modal, Typography } from "antd";
import {
  CheckCircleTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { getAlertRules, deleteAlertRule, runViolationRule, reRunViolationRule } from "./AlertRulesProcessing";
import { AlertCreationModal } from "./AlertCreationModal";

import { useAlertCustomerHauler } from "../../Pages/atoms";
import { useAtom } from "jotai";

const NoRulesFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "10px",
      }}
    >
      <ExclamationCircleOutlined
        style={{
          fontSize: "10rem",
          color: "grey",
        }}
      />
      <Typography.Title
        level={4}
        style={{
          color: "grey",
        }}
      >
        No Alert Rules Set
      </Typography.Title>
      <Typography.Text
        style={{
          color: "grey",
        }}
      >
        Click "Add New Rule" to create a new alert rule
      </Typography.Text>
    </div>
  );
};

const AlertRuleItem = ({ rule, onEdit, onDelete }) => {
  const ruleId = rule.rule_id;
  const ruleName = rule.name;
  const lineItemToMatch = rule.lineItemToMatch;
  const columns = rule.columns;

  const handleEdit = () => {
    onEdit(JSON.parse(JSON.stringify(rule)));
  };

  const handleDelete = () => {
    onDelete(ruleId);
  };

  let displayMessage = "";
  if (ruleName === "CompareAmount") {
    displayMessage = `Compare the Amount of ${lineItemToMatch}`;
  } else if (ruleName === "CompareRateGiven") {
    displayMessage = `Compare the Rate Given for ${lineItemToMatch}`;
  } else if (ruleName === "CompareRateCalculated") {
    displayMessage = `Calculate the Rate by providing the Amount and Quantity for ${lineItemToMatch}`;
  } else {
    displayMessage = `Unknown rule: ${ruleName}`;
  }

  return (
    <List.Item
      actions={[
        <a key="alert-rule-edit" onClick={handleEdit}>
          Edit
        </a>,
        <a key="alert-rule-delete" onClick={handleDelete}>
          Delete
        </a>,
      ]}
    >
      <List.Item.Meta
        avatar={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        title={<a>{ruleName}</a>}
        description={displayMessage}
      />
    </List.Item>
  );
};

export const AlertRulesBox = ({ customerName, haulerName, invoiceColumns, jobId }) => {
  // data states
  const [currentRules, setCurrentRules] = useState([]);
  const [alertCreationModalVisible, setAlertCreationModalVisible] =
    useState(false);

  const [selectedRuleToEdit, setSelectedRuleToEdit] = useState(null);
  const [_, setAlertCustomerHauler] = useAtom(useAlertCustomerHauler);

  useEffect(() => {
    getAlertRules(customerName, haulerName).then((rules) => {
      setCurrentRules(rules);
      setAlertCustomerHauler({ customerName, haulerName });
    });

    return () => {
      setAlertCustomerHauler({});
    };
  }, [customerName, haulerName]);

  const handleDelete = async (ruleId) => {
    // remove the rule with ruleId from the currentRules
    const deleteResult = await deleteAlertRule(
      customerName,
      haulerName,
      ruleId
    );

    if (!deleteResult) {
      console.error(`Error deleting rule with id: ${ruleId}`);
      message.error(`Error deleting rule with id: ${ruleId}`);
      return;
    }

    setCurrentRules((prev) => {
      return prev.filter((rule) => rule.rule_id !== ruleId);
    });
    message.success(`Successfully deleted rule`);
  };

  const handleEdit = (rule) => {
    setSelectedRuleToEdit(rule);
    setAlertCreationModalVisible(true);
  };

  const handleRuleCreate = () => {
    setAlertCreationModalVisible(false);
    setSelectedRuleToEdit(null);

    getAlertRules(customerName, haulerName).then((rules) => {
      setCurrentRules(rules);
    });
  };
  const handleAlertCreationModalCancel = () => {
    setAlertCreationModalVisible(false);
    setSelectedRuleToEdit(null);
  };
  const handleRunViolations = async () => {
    const response = await runViolationRule(jobId);
    console.log(response);
    message.success(`Successfully ran violation rules on current invoice`, 6);
  };
  const handleReRunViolations = async () => {
    const response = await reRunViolationRule(customerName, haulerName);
    console.log(response);
    message.success(`Initiated running violation rules on all invoices for ${customerName}, please reload the page after 1 minute`, 6);
  };

  return (
    <>
      <div
        style={{
          width: "800px",
          height: "auto",
          margin: "auto",
        }}
      >
        {currentRules.length === 0 ? (
          <NoRulesFound />
        ) : (
          <List itemLayout="horizontal">
            {currentRules.map((rule, _) => (
              <AlertRuleItem
                key={`alert-rule-item-${rule.rule_id}`}
                rule={rule}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </List>
        )}
        <div
          className="alert-rule-buttons"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "2rem"
          }}
        >
          <div>
            {/* To show the "Run Violations" and "Re-Run Violations" buttons only when there is at least one alert rule */}
            {currentRules.length > 0 && (
              <div style={{ display: "flex" }}>
                <Button type="primary" onClick={handleRunViolations} >
                  Run Violations
                </Button>
                <div style={{ marginRight: "10px" }}></div>
                {/* to give gap in between the buttons */}
                <Button type="primary" onClick={handleReRunViolations}>
                  Re-Run Violations
                </Button>
              </div>
            )}
          </div>
          <Button
            type="primary"
            onClick={() => {
              setAlertCreationModalVisible(true);
            }}
          >
            Add New Rule
          </Button>
        </div>
      </div>

      <AlertCreationModal
        isOpen={alertCreationModalVisible}
        providedRuleSettings={selectedRuleToEdit}
        onCreate={handleRuleCreate}
        onCancel={handleAlertCreationModalCancel}
        invoiceColumns={invoiceColumns}
      />
    </>
  );
};

export const AlertRulesBoxModal = ({
  isOpen,
  handleClose,
  customerName,
  haulerName,
  width,
  invoiceColumns,
  jobId
}) => {
  return (
    <Modal
      title="Alert Rules"
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      width={width || 900}
    >
      <AlertRulesBox
        customerName={customerName}
        haulerName={haulerName}
        invoiceColumns={invoiceColumns}
        jobId={jobId}
      />
    </Modal>
  );
};
