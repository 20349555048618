import React from 'react'
import myImage from '../assets/images/ai-icon.png'

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-logo">
        {/* Replace the img src with your custom logo */}
        <img src={myImage} alt="Logo" />
      </div>
      <div className="loader-spinner"></div>
    </div>
  )
}

export default Loader
