import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import {
  useDocuments,
  useLoading
} from "./atoms";
import {
  useHistory,
} from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import Dropzone from "../Components/Dropzone.js";

import { faEdit, faTrash, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { Typography } from 'antd';
import { UploadDocumentButton } from '../Components/UploadButtons';

function convertTimestamp(timestamp) {
  let newDate = new Date(timestamp);
  let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return newDate.toLocaleDateString('en-US', options);
}

const YTable = ({ doc, displayCustomerName }) => {
  const [documents, setDocuments] = useAtom(useDocuments);
  const [isLoading, setIsLoading] = useAtom(useLoading);
  const ws = useRef(null);
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const customerName = queryParams.get('customer');
  const haulerName = queryParams.get('hauler');


  const [monthOfService, setMonthOfService] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedMonth, setEditedMonth] = useState('');

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [comments, setComments] = useState({});

  
  // Function to toggle editing state for a row
  const toggleEditing = (job_id, currentMonthOfService) => {
    setEditedMonth(currentMonthOfService);
    setDocuments(prevDocuments =>
      prevDocuments.map(doc =>
        doc.job_id === job_id ? { ...doc, isEditing: !doc.isEditing, editedMonthOfService: doc.month_of_service } : doc
      )
    );
  };

  const handleCancelEdit = (job_id) => {
    setDocuments(prevDocuments =>
      prevDocuments.map(doc =>
        doc.job_id === job_id ? { ...doc, isEditing: false } : doc
      )
    );
  };

  const handleEditClick = (monthOfService) => {
    setIsEditing(true);
    setEditedMonth(monthOfService);
  };

  const handleSave = async (job_id) => {
    try {
      const docToUpdate = documents.find(doc => doc.job_id === job_id);
      if (!docToUpdate) return;
  
      // Make API call to save edited month with query parameter
      await axios.post(`/documents/${job_id}/put_month_of_service?month_of_service=${docToUpdate.editedMonthOfService}`);
      setDocuments(prevDocuments =>
        prevDocuments.map(doc =>
          doc.job_id === job_id
            ? { ...doc, month_of_service: docToUpdate.editedMonthOfService, isEditing: false }
            : doc
        )
      );
    } catch (error) {
      console.error('Error saving month:', error);
    }
  };

  const routeChange = (job_id) =>{ 
    let curPath = history.location.pathname
    let path = `/customer/${customerName}/${haulerName}/invoice/${job_id}`; 
    history.push(path);
  }

  const getData = async () => {
    try {
      const response = await axios.get(
        `/documents`
      );
      const filteredDocuments = await response.data.filter((item) => {
          return item.customer_id === customerName && item.hauler_id === haulerName;
        }
        )
      setDocuments(filteredDocuments);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setDocuments([]);
      setIsLoading(false);
    }
  };

  const wsRemoveData = (data) => {
    let item = data.message.item
    let temp = JSON.parse(JSON.stringify(documents));
    let index = temp.findIndex((x) => x.job_id === item.job_id);
    if (index > -1) {
      temp.splice(index, 1);
    }
    setDocuments(temp);
  }

  const wsUpdateData = (data) => {
    let item = data.message.item
    if (!(item.customer_id === customerName) || !(item.hauler_id === haulerName)) {
      return
    }
    let temp = JSON.parse(JSON.stringify(documents));
    let index = temp.findIndex((x) => x.job_id === item.job_id);
    if (index > -1) {
      temp[index] = item;
    } else {
      temp.unshift(item);
    }
    setDocuments(temp);
  }

  const handleEdit = async (doc) => {
    const newName = prompt('Enter the new name:');
    if (newName !== null) {
      try {
        // Make API call to update document name on the server
        const response = await axios.post(`/documents/${doc.job_id}/alter_document?display_name=${newName}`);
        if (response.status === 200) {
          // Update document name in the frontend
          setDocuments((prevDocuments) => {
            return prevDocuments.map((d) => {
              if (d.job_id === doc.job_id) {
                return { ...d, display_name: newName }; //`display_name` represents the new column in metadata table
              }
              return d;
            });
          });
          console.log(`Document name with job_id ${doc.job_id} updated successfully.`);
        } else {
          throw new Error('Failed to update document name');
        }
      } catch (error) {
        console.error('Error updating document name:', error);
      }
    }
  };

  const handleDeleteDocument = async (job_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this document?');
    if (confirmDelete) {
      try {
        // Make API call to delete document on the server
        const response = await axios.post(`/documents/${job_id}/delete_document`);
        console.log(response)
        if (response.status != 200) {
          throw new Error('Failed to delete document');
        }
        // If deletion on the server is successful, remove the document from the client-side state
        setDocuments((prevDocuments) => {
          return prevDocuments.filter((doc) => doc.job_id !== job_id);
        });

        // For testing purposes
        console.log(`Document with job_id ${job_id} deleted (simulated on client).`);
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleComment = (doc) => {
    let user_comment = prompt("Please enter your note"); 
    if(user_comment !== null) { 
      axios.post(`/documents/${doc.job_id}/put_comment?user_comment=${user_comment}`) 
        .then(response => {
          // Update comments state with the new comment
          setComments((prevComments) => ({
            ...prevComments,
            [doc.job_id]: response.data.user_comment 
          }));
        })
        .catch(error => {
          console.error('Error adding comment:', error);
        });
    }
  }
  
  // Function to fetch existing comments
  const fetchComments = (doc) => {
    axios.get(`/documents/${doc.job_id}/get_comment`)
      .then(response => {
        // Update comments state with existing comment
        setComments((prevComments) => ({
          ...prevComments,
          [doc.job_id]: response.data.user_comment
        }));
      })
      .catch(error => {
        console.error('Error fetching comment:', error);
      });
  }


  const documentRendererUrl = (doc) => {
    const doc_s3_key = doc.s3_key;

    return (
      <div
        style={{ display: 'flex'}}
        onMouseEnter={() => setHoveredIndex(doc.job_id)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <div style={{ alignSelf: 'center', marginLeft: '0.5 rem', width: '28rem' }}>
        {doc.display_name || doc_s3_key}
        {hoveredIndex === doc.job_id && (
          <span className="icon-container" style={{ marginLeft: '0.5rem' }}>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(doc)} />
            <TrashIcon job_id={doc.job_id} />
            <FontAwesomeIcon icon={faComment} style={{ marginLeft: '0.6rem'}} onClick={() => handleComment(doc)}/>
          </span>
        )}
        <span style={{marginLeft: '0.5rem'}}> {doc.user_comment}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (doc) {
      fetchComments(doc);
    }
  }, [doc]);

  useEffect(() => {
    getData();
  }, [customerName]); // Trigger effect when customerName changes

  useEffect(() => {
    ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    ws.current.onopen = () => {
      console.log("connected");
    }
    
    ws.current.onclose = () => {
      console.log("disconnected");
    }

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    }
  }, []);
  
  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      console.log('Message recieved: ', e)
      let parsedData = JSON.parse(e.data);
      let action = parsedData.action;

      if (action === "updateRecord") {
        wsUpdateData(parsedData)
      } else if ( action === "removeRecord" ) {
        wsRemoveData(parsedData)
      }
    }
  }, [documents])

  const viewTableButton = (job_id, customer_id) => {
    return ( <button
        id="View Table Data"
        type="button"
        className="btn btn-danger deleteButton"
        style={{
          color: "#fff",
          fontSize: "1rem",
        }}
        onClick={() => {
          routeChange(job_id)
        }}
      >View</button>)
  }

  const downloadPdfButton = (job_id) => {

    return ( <button
        id="Download PDF"
        type="button"
        className="btn btn-danger deleteButton"
        style={{
          color: "#fff",
          fontSize: "0.9rem",
        }}
        onClick={() => {
          axios.get(`/documents/${job_id}/getpresignedurl`).then((res) => {
            let url = res.data.url
            console.log('url ', url)
            window.open(url, '_blank')
          }).catch((err) => {
            console.log(err)
          })
        }}
      >Download</button>)
  }

  const TrashIcon = ({ job_id }) => (
    <FontAwesomeIcon
      icon={faTrash}
      style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
      onClick={() => handleDeleteDocument(job_id)}
    />
  );

  const StatusCircle = ({ status, type }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    let color;
    let tooltipText = '';
  
    switch (status.toLowerCase()) {
      case 'not started':
        color = 'grey';
        tooltipText = `${type} - Not Started`;
        break;
      case 'succeeded':
        color = 'green';
        tooltipText = `${type} - Succeeded`;
        break;
      case 'running':
        color = 'yellow';
        tooltipText = `${type} - Running`;
        break;
      case 'validation_failed':
        color = 'red';
        tooltipText = `${type} - Validation Failed`;
        break;
      case 'errored':
        color = 'red';
        tooltipText = `${type} - Error`;
        break;
      default:
        color = 'blue';
        tooltipText = `${type} - Unknown`;
    }
    
    const circleStyle = {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: color,
      margin: '0 5px',
      position: 'relative',
      cursor: 'pointer',
    };
  
    const tooltipStyle = {
      position: 'absolute',
      bottom: '50%',
      left: '100%',
      transform: 'translateY(-50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: '#fff',
      padding: '5px',
      borderRadius: '10px',
      display: isTooltipVisible ? 'block' : 'none',
      whiteSpace: 'nowrap',  // Prevents text from wrapping to the next line
      maxWidth: '250px',  // Adjust the maximum width as needed
      // overflow: 'hidden',  // Hides any content that overflows the container
      textOverflow: 'ellipsis',
      transition: 'display 0.3s ease',
    };
  
    return (
      <div
        style={circleStyle}
        onMouseEnter={() => {
          setTooltipVisible(true);
        }}
        onMouseLeave={() => {
          setTooltipVisible(false);
        }}
      >
        <div style={tooltipStyle}>{tooltipText}</div>
      </div>
    );
  };

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
    }}>
      {/* Show selected customer and hauler names */}
      <div style={{ margin: '1rem auto', textAlign: 'center', backgroundColor: 'black', padding: '1px', borderRadius: '20px', maxWidth: '300px'}}>
      <Typography style={{ color: 'skyblue' }}>
        <b>{displayCustomerName ? `${displayCustomerName} - ${haulerName}` : `${customerName} - ${haulerName}`}</b>
      </Typography>
      </div>
      {/* TABLE */}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : documents ? (
        <>
        <div style={{
          height: '5vh',
          flexDirection: 'row',
          display: 'flex'
        }}>
            <div style={{
              margin: 'auto 2.5rem 0.5rem auto',
              height: '3.5rem'
            }}>
                <UploadDocumentButton
                    customerName={customerName}
                    haulerName={haulerName}
                />
            </div>

        </div>
        <div 
            className="tips-table-style-1"
        style={{
          height: '90vh',
          margin: '2rem 2rem 0 2rem'
        }}>
          <table>
            <tr>
              <th>STATUS</th>
              <th>DOCUMENT</th>
              <th>INVOICE DATE</th>
              <th>MONTH OF SERVICE</th>
              <th>CREATED AT</th>
              <th></th>
            </tr>
            {documents.map((row, index) => (
              <tr key={index}>
                <td data-th="STATUS">
                  <div style={{ display: 'flex' }}>
                  <StatusCircle status={row.status} type="Extraction Pipeline" />
                  <StatusCircle status={row.metadata_extract_status} type="Metadata Extract" />
                  <StatusCircle status={row.table_extract_status} type="Table Extract" />
                  </div>
                </td>
                <td data-th="DOCUMENT" style={{width: '2rem'}}>
                  {documentRendererUrl(row)}
                </td>
                <td data-th="INVOICE DATE">{row.invoice_date}</td>
                <td data-th="MONTH OF SERVICE" style={{ width: '300px' }}>
                  {row.isEditing ? (
                    <>
                      <input
                        type="text"
                        value={row.editedMonthOfService}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setDocuments(prevDocuments =>
                            prevDocuments.map(doc =>
                              doc.job_id === row.job_id
                                ? { ...doc, editedMonthOfService: newValue }
                                : doc
                            )
                          );
                        }}
                        style={{ backgroundColor: 'black', color: 'white', padding: '5px', width: '100%', maxWidth: '13rem', boxSizing: 'border-box', overflowWrap: 'break-word' }}
                        autoFocus
                      />
                      <CheckCircleFilled onClick={() => handleSave(row.job_id, editedMonth)} style={{ marginLeft: '5px', cursor: 'pointer', color: 'green', fontSize: '20px' }} />
                      <CloseCircleFilled onClick={() => handleCancelEdit(row.job_id)} style={{ marginLeft: '5px', cursor: 'pointer', color: 'red', fontSize: '20px' }} />
                    </>
                  ) : (
                    <>
                      {row.month_of_service}
                      <EditOutlined onClick={() => toggleEditing(row.job_id, row.month_of_service)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                    </>
                  )}
                </td>
                <td data-th="CREATED AT">{convertTimestamp(row.created_at)}</td>
                <td data-th="ACTIONS">
                  <div className="dashboard-btn-flex-container">
                    <div className="dshbrd-row">
                      { row.table_extract_status === 'SUCCEEDED' ? viewTableButton(row.job_id, row.template_id) : null}
                    </div>
                    <div className="dshbrd-row">
                      { row.table_extract_status === 'SUCCEEDED' ? downloadPdfButton(row.job_id) : null}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
        </>
      ) : (
        <div>Unable to load any documents</div>
      )}
    </div>

  );
};

export default YTable;
