import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../Components/Loader";
import {
  Card,
  Input,
  Button,
  Modal,
  Form,
  message,
  Select,
  Typography,
  Space,
} from "antd";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const { Meta } = Card;

const LandingPage = () => {
  const [customerData, setCustomerData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [isInvoiceDateUpdated, setIsInvoiceDateUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [customerName, setCustomerName] = useState("");
  const [haulerName, setHaulerName] = useState("");
  const history = useHistory();
  const [isEditing, setIsEditing] = useState({});
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [hoveredCustomer, setHoveredCustomer] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [deletedCustomers, setDeletedCustomers] = useState([]);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [haulerToDelete, setHaulerToDelete] = useState("");

  const fetchCustomerData = async (customerName, haulerName, isDeleted) => {
    try {
      const response = await axios.get("/customer-hauler-pair", {
        params: {
          customer_name: customerName,
          hauler_name: haulerName,
          isDeleted: isDeleted
        },
      });
      setCustomerData(response.data || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData(customerName, haulerName, false);
  }, [customerName, haulerName, documentData, dataUpdated]);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await axios.get("/documents");
        setDocumentData(response.data);
        setIsInvoiceDateUpdated(true); // Set flag to true once the document data is updated
        setIsLoading(false);
        // setDataUpdated(!dataUpdated); // Trigger a re-render by flipping the state
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setIsLoading(false);
      }
    };

    fetchDocumentData();
  }, [isInvoiceDateUpdated, dataUpdated]);

  const handleCustomerClick = (customer_name, displayCustomerName) => {
    setNewCustomerName(displayCustomerName);
    const associatedHaulers = customerData.filter(
      (customer) =>
        customer.customer_name === customer_name && customer.hauler_name
    );
    const encodedCustomerName = encodeURIComponent(customer_name);
  
    if (associatedHaulers.length === 1) {
      const haulerName = associatedHaulers[0].hauler_name; //This code applies when there is only one hauler, in which case it should consider the first one
      const encodedHaulerName = encodeURIComponent(haulerName);
      history.push(`/dashboard?customer=${encodedCustomerName}&hauler=${encodedHaulerName}`);
    } else if (associatedHaulers.length > 1) {
      const haulerNames = associatedHaulers.map((h) => h.hauler_name);
      console.log("Multiple haulers found: ", haulerNames);
      history.push(`/customer/${encodedCustomerName}/hauler`);
    } else {
      console.log("No more than one hauler found. Skipping...");
      history.push(`/customer/${encodedCustomerName}`);
    }
  };
  

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleAddCustomer = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await axios.post("/customer-hauler-pair", {
        customerName: values.customerName,
        haulerName: values.haulerName,
      });
      message.success("Successfully added Customer - Hauler pair!");
      setIsModalVisible(false); // Close the modal
      setCustomerName(values.customerName);
      setHaulerName(values.haulerName); // Trigger a refresh by updating customerName
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEditCustomer = (customer_name) => {
    const customerToEdit = customerData.find(
      (pair) => pair.customer_name === customer_name
    );
    const displayCustomerName = customerToEdit
      ? customerToEdit.display_customer_name || customer_name
      : customer_name;
    setIsEditing({ ...isEditing, [customer_name]: true });
    setEditingCustomer(customer_name);
    setNewCustomerName(displayCustomerName);
  };

  const handleCancelEdit = () => {
    setEditingCustomer(null);
    setIsEditing({ ...isEditing, [editingCustomer]: false });
    setNewCustomerName("");
  };

  const getHaulerName = (customerName) => {
    const associatedHauler = customerData.find(
      (pair) => pair.customer_name === customerName
    );
    return associatedHauler ? associatedHauler.hauler_name : "";
  };

  const handleConfirmEdit = async () => {
    try {
      await axios.post("/customer-hauler-pair", {
        customerName: editingCustomer,
        haulerName: getHaulerName(editingCustomer),
        display_customer_name: newCustomerName,
      });
      setIsEditing({ ...isEditing, [editingCustomer]: false });
      setEditingCustomer(null);
      setNewCustomerName("");
      fetchCustomerData(editingCustomer, getHaulerName(editingCustomer));
      setDataUpdated(!dataUpdated); // Trigger a re-render after successful edit
    } catch (error) {
      console.error("Error updating customer name:", error);
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleConfirmClick = (e) => {
    e.stopPropagation();
    handleConfirmEdit();
  };

  const handleCancelClick = (e) => {
    e.stopPropagation();
    handleCancelEdit();
  };

  const handleDeleteCustomer = (customerName) => {
    const associatedHauler = customerData.find(
      (pair) => pair.customer_name === customerName
    );
    const haulerName = associatedHauler ? associatedHauler.hauler_name : "";
    setCustomerToDelete(customerName);
    setHaulerToDelete(haulerName);
  };

  // Function to handle deletion confirmation
  const handleConfirmDelete = async () => {
    try {
      const queryParams = new URLSearchParams({
        customerName: customerToDelete,
        haulerName: haulerToDelete,
        isDeleted: true,
      });
      console.log('Query params:', queryParams);
  
      const response = await axios.delete(`/customer-hauler-pair?${queryParams}`);
      console.log('Response status:', response.status);
  
      if (response.status === 200) {
        message.success(`"${customerToDelete}" Customer card is deleted.`);
        setDeletedCustomers([...deletedCustomers, customerToDelete]); // Add deleted customer to the array
        fetchCustomerData(customerName, haulerName, false); // Fetch non-deleted customers
      } else {
        message.error('Failed to delete customer and hauler.');
      }
    } catch (error) {
      console.error('Error deleting customer and hauler:', error);
      message.error('Failed to delete customer and hauler.');
    } finally {
      setCustomerToDelete(null); // Reset the customer to delete
      setHaulerToDelete(""); // Reset the hauler to delete
    }
  };
  
  // Function to handle cancellation of deletion
  const handleCancelDelete = () => {
    setCustomerToDelete(null); // Reset the customer to delete
  };

  // Function to filter out unique customers and get their latest and previous document details
  const getUniqueCustomers = () => {
    const uniqueCustomers = {};

    // Initialize document count for each customer to 0
    customerData
      .forEach((pair) => {
        const { customer_name, display_customer_name } = pair;
        uniqueCustomers[customer_name] = {
          documentCount: 0,
          latestDocument: null,
          previousDocument: null,
          display_customer_name: display_customer_name || customer_name,
        };
      });

    // Loop through document data to associate document details with customers
    documentData.forEach((document) => {
      const { customer_id } = document;
      // Initialize the customer object if it doesn't exist
      if (!uniqueCustomers[customer_id]) {
        uniqueCustomers[customer_id] = {
          documentCount: 0,
          latestDocument: null,
          previousDocument: null,
          display_customer_name: "",
        };
      }

      // Increment document count for the customer associated with the document
      uniqueCustomers[customer_id].documentCount++;

      // Update latest and previous document details as before
      if (
        !uniqueCustomers[customer_id].latestDocument ||
        document.invoice_date >
          uniqueCustomers[customer_id].latestDocument.invoice_date
      ) {
        uniqueCustomers[customer_id].previousDocument =
          uniqueCustomers[customer_id].latestDocument;
        uniqueCustomers[customer_id].latestDocument = {
          invoice_date: document.invoice_date,
          invoice_number: document.invoice_number,
          month_of_service: document.month_of_service,
        };
      } else if (
        !uniqueCustomers[customer_id].previousDocument ||
        (document.invoice_date >
          uniqueCustomers[customer_id].previousDocument.invoice_date &&
          document.invoice_date <
            uniqueCustomers[customer_id].latestDocument.invoice_date &&
          document.invoice_date !==
            uniqueCustomers[customer_id].latestDocument.invoice_date)
      ) {
        uniqueCustomers[customer_id].previousDocument = {
          invoice_date: document.invoice_date,
          invoice_number: document.invoice_number,
          month_of_service: document.month_of_service,
        };
      }
    });
    // Filter customers based on the search term
    const filteredCustomers = Object.keys(uniqueCustomers).filter(
      (customerName) =>
        customerName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort customer names alphabetically based on display_customer_name or customerName
    const sortedCustomerNames = filteredCustomers.sort((a, b) => {
      const aName = uniqueCustomers[a].display_customer_name || a;
      const bName = uniqueCustomers[b].display_customer_name || b;
      return aName.localeCompare(bName);
    });

    const customerDetailsArray = sortedCustomerNames
    .filter((customerName) => {
      // Check if the customer is not deleted
      const customerPair = customerData.find(
        (pair) => pair.customer_name === customerName
      );
      return !customerPair || !customerPair.isDeleted;
    })
    .map((customerName) => {
      const {
        documentCount,
        latestDocument,
        previousDocument,
        display_customer_name,
      } = uniqueCustomers[customerName];

      const previousDoc =
        previousDocument &&
        previousDocument.invoice_number === latestDocument.invoice_number
          ? null
          : previousDocument;

      return {
        customerName,
        documentCount,
        latestDocument,
        previousDocument: previousDoc,
        display_customer_name,
      };
    });
    return customerDetailsArray;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <Button
          type="primary"
          onClick={handleAddCustomer}
          style={{ position: "absolute", top: 70, right: 10 }}
        >
          Add Customer
        </Button>
      </div>
      <Input
        placeholder="Search customer names"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        style={{
          marginBottom: "16px",
          width: "300px",
          backgroundColor: "#f0f0f0",
          color: "grey",
        }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          {getUniqueCustomers()
          .filter((customer) => !deletedCustomers.includes(customer.customerName)) // Filter out customers with isDeleted = true
          .map(
            (customer, index) =>
               (
                <Card
                  key={index}
                  style={{
                    width: 380,
                    height: 270,
                    margin: "8px",
                    backgroundColor: "#e6e6fa",
                    borderColor: "#2f4f4f",
                  }}
                  hoverable
                  onClick={() => handleCustomerClick(customer.customerName)}
                  onMouseEnter={() => setHoveredCustomer(customer.customerName)}
                  onMouseLeave={() => setHoveredCustomer(null)}
                >
                  <Space align="start">
                    {editingCustomer === customer.customerName ? (
                      <>
                        <Input
                          value={newCustomerName}
                          onChange={(e) => setNewCustomerName(e.target.value)}
                          style={{
                            marginBottom: "5px",
                            backgroundColor: "#f0f0f0",
                            height: "30px",
                          }}
                          onClick={handleInputClick}
                        />
                        <Space>
                          <CheckCircleOutlined
                            onClick={handleConfirmClick}
                            style={{ fontSize: "20px", marginTop: "12px" }}
                          />
                          <CloseCircleOutlined
                            onClick={handleCancelClick}
                            style={{ fontSize: "20px", marginTop: "12px" }}
                          />
                        </Space>
                      </>
                    ) : (
                      <>
                        <Meta
                          title={
                            customer.display_customer_name ||
                            customer.customerName
                          }
                          style={{ marginBottom: "5px" }}
                        />
                        <EditOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditCustomer(customer.customerName);
                          }}
                          style={{
                            fontSize: "15px",
                            visibility:
                              hoveredCustomer === customer.customerName &&
                              !isEditing[customer.customerName]
                                ? "visible"
                                : "hidden",
                          }}
                        />
                        <DeleteOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCustomer(customer.customerName);
                          }}
                          style={{
                            fontSize: "15px",
                            visibility:
                              hoveredCustomer === customer.customerName &&
                              !isEditing[customer.customerName]
                                ? "visible"
                                : "hidden",
                          }}
                        />
                      </>
                    )}
                  </Space>
                  {/* Displaying latest document details */}
                  <p style={{ marginBottom: "8px" }}>
                    Amount of documents: {customer.documentCount}
                  </p>
                  <p style={{ marginBottom: "5px" }}>
                    Latest Invoice Date:{" "}
                    {customer.latestDocument?.invoice_date || "N/A"}
                  </p>
                  <p style={{ marginBottom: "5px" }}>
                    Latest Invoice Number:{" "}
                    {customer.latestDocument?.invoice_number || "N/A"}
                  </p>
                  <p style={{ marginBottom: "12px" }}>
                    Latest Month Of Service:{" "}
                    {customer.latestDocument?.month_of_service || "N/A"}
                  </p>

                  {/* Display previous document details if available */}
                  {customer.previousDocument && (
                    <>
                      <p style={{ marginBottom: "5px" }}>
                        Previous Invoice Date:{" "}
                        {customer.previousDocument.invoice_date}
                      </p>
                      <p style={{ marginBottom: "5px" }}>
                        Previous Invoice Number:{" "}
                        {customer.previousDocument.invoice_number}
                      </p>
                      <p style={{ marginBottom: "5px" }}>
                        Previous Month Of Service:{" "}
                        {customer.previousDocument.month_of_service}
                      </p>
                    </>
                  )}
                </Card>
              )
          )}
          <Modal
            title="Add Customer"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} layout="vertical" name="addCustomerForm">
              <Form.Item
                name="customerName"
                label="Customer Name"
                rules={[
                  { required: true, message: "Please enter customer name" },
                ]}
              >
                <Input style={{ height: "35px", backgroundColor: "white" }} />
              </Form.Item>
              <Form.Item
                name="haulerName"
                label="Hauler Name"
                rules={[
                  { required: true, message: "Please select hauler name" },
                ]}
              >
                <Select>
                  {Array.from(
                    new Set(
                      customerData.map((customer) => customer.hauler_name)
                    )
                  ).map((haulerName) => (
                    <Option key={haulerName} value={haulerName}>
                      {haulerName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
      <div>
      <Modal
        title="Confirm Deletion"
        open={customerToDelete !== null}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
      >
        <div>Are you sure you want to delete the customer {customerToDelete}?</div>
      </Modal>
      </div>
    </div>
  );
};

export default LandingPage;