import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Select, Input } from "antd";

import {
  useAlertCreationFormData,
  useAlertCustomerHauler,
} from "../../Pages/atoms";
import { useAtom } from "jotai";

import {
  ALERT_RULES,
  parseFormIntoRules,
  parseRuleIntoForm,
  createAlertRule,
  updateAlertRule,
} from "./AlertRulesProcessing";

const AlertCreationRuleChoice = ({ onSelect, ruleChoiceProvided }) => {
  const [description, setDescription] = useState("");
  const [currentRule, setCurrentRule] = useState(null);

  const handleSelect = (value) => {
    onSelect(value);
    const selectedOption = Object.values(ALERT_RULES).find(
      (option) => option.name === value
    );
    setCurrentRule(selectedOption.name);
    setDescription(selectedOption.description);
  };

  useEffect(() => {
    if (ruleChoiceProvided) {
      handleSelect(ruleChoiceProvided);
    }
  }, [ruleChoiceProvided]);

  return (
    <div>
      <Select
        style={{ width: "100%" }}
        placeholder="How do you want the alert to be triggered?"
        onChange={handleSelect}
        value={currentRule}
      >
        {Object.values(ALERT_RULES).map((option) => (
          <Select.Option value={option.name} key={option.name}>
            <div style={{ wordBreak: "break-all" }}>{option.label}</div>
          </Select.Option>
        ))}
      </Select>
      <div
        style={{
          fontStyle: "italic",
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: "0.8em",
          paddingLeft: "10px",
        }}
      >
        {description}
      </div>
    </div>
  );
};

const AlertCreationFormColumnsSelect = ({ label, name, options, sKey }) => (
  <Form.Item
    label={label}
    name={name}
    rules={[{ required: true, message: "You must select a column" }]}
  >
    <Select>
      {options.map((option) => (
        <Select.Option value={option} key={`${label}-${sKey}-${option}`}>
          {option}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const AlertCreationFormColumnsInput = ({ ruleChoice, columnNames }) => {
  switch (ruleChoice) {
    case ALERT_RULES.COMPARE_AMOUNT.name:
      return (
        <>
          <AlertCreationFormColumnsSelect
            label="Look for line item in column:"
            name="columnForTextMatch"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_AMOUNT.name}-columnForTextMatch`}
          />
          <AlertCreationFormColumnsSelect
            label="Take Amount from column:"
            name="columnForComparison"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_AMOUNT.name}-columnForComparison`}
          />
        </>
      );
    case ALERT_RULES.COMPARE_RATE_GIVEN.name:
      return (
        <>
          <AlertCreationFormColumnsSelect
            label="Look for line item in column:"
            name="columnForTextMatch"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_RATE_GIVEN.name}-columnForTextMatch`}
          />
          <AlertCreationFormColumnsSelect
            label="Take Rate from column:"
            name="columnForComparison"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_RATE_GIVEN.name}-columnForComparison`}
          />
        </>
      );
    case ALERT_RULES.COMPARE_RATE_CALCULATED.name:
      return (
        <>
          <AlertCreationFormColumnsSelect
            label="Look for line item in column:"
            name="columnForTextMatch"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_RATE_CALCULATED.name}-columnForTextMatch`}
          />
          <AlertCreationFormColumnsSelect
            label="Take Amount from column:"
            name="columnForAmount"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_RATE_CALCULATED.name}-columnForAmount`}
          />
          <AlertCreationFormColumnsSelect
            label="Take Quantity from column:"
            name="columnForQuantity"
            options={columnNames}
            sKey={`${ALERT_RULES.COMPARE_RATE_CALCULATED.name}-columnForQuantity`}
          />
        </>
      );
    default:
      return <Form.Item label="Not Found"></Form.Item>;
  }
};

const AlertCreationForm = ({
  ruleChoice,
  onFormInstanceReady,
  invoiceColumns,
}) => {
  const [form] = Form.useForm();

  const [_, setAlertCreationFormData] = useAtom(useAlertCreationFormData);

  useEffect(() => {
    onFormInstanceReady(form);
  }, []);

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setAlertCreationFormData(values);
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  };

  return (
    <Form form={form} onFinish={handleConfirm}>
      <Form.Item
        label="Line Item to Match"
        name="lineItemToMatch"
        rules={[
          { required: true, message: "You must provide a line item to match" },
        ]}
        extra="Enter a part of the text of the line item to match."
      >
        <Input />
      </Form.Item>
      <AlertCreationFormColumnsInput
        ruleChoice={ruleChoice}
        columnNames={invoiceColumns}
      />
    </Form>
  );
};

export const AlertCreationModal = ({
  isOpen,
  invoiceColumns,
  onCreate,
  onCancel,
  providedRuleSettings,
}) => {
  const [formInstance, setFormInstance] = useState();
  const [ruleChoice, setRuleChoice] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [_a, setAlertCreationFormData] = useAtom(useAlertCreationFormData);
  const [alertCustomerHauler, _b] = useAtom(useAlertCustomerHauler);

  const handleRuleChoice = (value) => {
    setRuleChoice(value);
  };

  useEffect(() => {
    const setFormValues = async (formValues) => {
      await formInstance?.setFieldsValue(formValues);
    };

    if (providedRuleSettings && formInstance) {
      // Parse rule settings and set form values
      const parsedValues = parseRuleIntoForm(providedRuleSettings);

      setRuleChoice(parsedValues.ruleChoice);
      setFormValues(parsedValues.formValues);
      setEditMode(true);
    }
  }, [providedRuleSettings, formInstance]);

  const handleCreateRule = async () => {
    try {
      const values = await formInstance?.validateFields();

      setAlertCreationFormData(values);
      setRuleChoice(null);

      let updateSuccessful = false;
      if (editMode) {
        updateSuccessful = await updateAlertRule(
          alertCustomerHauler.customerName,
          alertCustomerHauler.haulerName,
          providedRuleSettings.rule_id,
          parseFormIntoRules(ruleChoice, values)
        );
      } else {
        updateSuccessful = await createAlertRule(
          alertCustomerHauler.customerName,
          alertCustomerHauler.haulerName,
          parseFormIntoRules(ruleChoice, values)
        );
      }

      formInstance?.resetFields();
      setEditMode(false);

      if (onCreate) {
        onCreate(values);
      }
    } catch (e) {
      console.log("Validation failed:", e);
    }
  };
  const handleCancel = () => {
    console.log("Cancel");
    setRuleChoice(null);
    setEditMode(false);

    if (onCancel) {
      onCancel();
    }
  };
  const handleFormInstanceReady = (instance) => {
    setFormInstance(instance);
  };

  return (
    <Modal
      title="Create New Alert Rule"
      open={isOpen}
      okText={editMode ? "Save" : "Create"}
      onOk={handleCreateRule}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: !ruleChoice,
      }}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <AlertCreationRuleChoice
          onSelect={handleRuleChoice}
          ruleChoiceProvided={providedRuleSettings?.name}
        />
        {ruleChoice && (
          <>
            <AlertCreationForm
              ruleChoice={ruleChoice}
              onFormInstanceReady={handleFormInstanceReady}
              invoiceColumns={invoiceColumns}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
