import { atom } from 'jotai'

export const useLoading = atom(true)
export const useDocuments = atom([])
export const useCurrentSelectedCustomer = atom(localStorage.getItem('currentCustomerID') || "")

// form state for AlertCreationModal

export const useAlertCreationFormData = atom({})
export const useAlertCustomerHauler = atom({})

