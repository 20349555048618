import React, { useState, useEffect } from "react";
import { Table, Input, Select, Button, AutoComplete } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const SavingsInvoiceSettings = ({
  savingsSettings,
  invoiceColumns,
  handleSavingSettingsChange,
  handleDeleteRow,
  editDeleteMode,
  invoiceTableData,
}) => {
  const [showBaselineCostColumn, setShowBaselineCostColumn] = useState(false);
  useEffect(() => {
    const shouldShowBaselineCostColumn = savingsSettings.some(
      (setting) =>
        setting.action === "Group & compare baseline" || setting.baselinecost
    );
    setShowBaselineCostColumn(shouldShowBaselineCostColumn);
  }, [savingsSettings]);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const searchOptions = (value, column) => {
    if (column === '') {
      return []
    }
    try {
      const filteredData = invoiceTableData.nodes.filter((data) =>
        String(data[column]).toLowerCase().includes(value.toLowerCase())
      );

      const showData = filteredData.map((data) => ({
        value: data[column],
        label: (
          <div>
            {data[column]}
            <div>Amount: {data.Amount}</div>
          </div>
        ),
      }));

      return showData;
    } catch (error) {
      console.error("searchOptions error");
      return [];
    }
  };

  const columns = [
    {
      title: "Enable",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (value, record) => (
        <div style={{ verticalAlign: "middle", textAlign: "center" }}>
          <input
            type="checkbox"
            checked={record.enabled}
            onChange={(e) =>
              handleSavingSettingsChange(
                record.key,
                "enabled",
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
    {
      title: "Savings Line Item Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Input
          value={text}
          style={{
            height: "33px",
            backgroundColor: "white",
            whiteSpace: "normal",
          }}
          onChange={(e) =>
            handleSavingSettingsChange(record.key, "name", e.target.value)
          }
        />
      ),
    },
    {
      title: "Hauler Invoice columns to search",
      dataIndex: "column",
      key: "column",
      render: (text, record) => (
        <Select
          value={text}
          onChange={(value) =>
            handleSavingSettingsChange(record.key, "column", value)
          }
          style={{ width: "200px", height: "33px", minWidth: "120px" }}
        >
          {invoiceColumns.map((column, index) => (
            <Option key={index} value={column}>
              {column}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Items to Match",
      dataIndex: "matcheditems",
      key: "matcheditems",
      render: (text, record) => (
        <AutoComplete
          value={text}
          onChange={(e) =>
            handleSavingSettingsChange(record.key, "matcheditems", e)
          }
          options={autoCompleteOptions}
          style={{ width: 200 }}
          onSelect={(data) => console.log("onSelect", data)}
          onSearch={(text) =>
            setAutoCompleteOptions(searchOptions(text, record.column))
          }
          placeholder="input here"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Select
          value={text}
          onChange={(value) => {
            handleSavingSettingsChange(record.key, "action", value);
          }}
          style={{ width: "200px", height: "33px", minWidth: "120px" }}
        >
          <Option value="Group together & add to baseline cost">
            Group together & add to baseline cost
          </Option>
          <Option value="Group & compare baseline">
            Group & compare baseline
          </Option>
        </Select>
      ),
    },
    ...(showBaselineCostColumn
      ? [
          {
            title: "Baseline Cost",
            dataIndex: "baselinecost",
            key: "baselinecost",
            render: (text, record) =>
              record.action === "Group & compare baseline" ? (
                <Input
                  value={record.baselinecost || ""}
                  placeholder="Enter baseline cost $"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove non-numeric characters
                    const numericValue = inputValue.replace(/[^0-9.]/g, "");
                    handleSavingSettingsChange(
                      record.key,
                      "baselinecost",
                      numericValue
                    );
                  }}
                  style={{
                    height: "33px",
                    backgroundColor: "white",
                    whiteSpace: "normal",
                    width: "100px",
                  }}
                />
              ) : null,
          },
        ]
      : []),
    ...(editDeleteMode
      ? [
          {
            title: "Delete",
            key: "delete",
            render: (text, record, index) => (
              <Button
                icon={<DeleteOutlined />}
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteRow(index)}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Table
        dataSource={savingsSettings}
        columns={columns}
        pagination={false}
        bordered={true}
        size="small"
        style={{ border: "1px solid #e8e8e8", width: "100%" }}
        rowClassName={(record) => (record?.updated ? "updated-row" : "")}
      />
    </>
  );
};
