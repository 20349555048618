import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import MetaAnalysisTable from "../Components/MetaAnalysisTable";
import { useParams } from "react-router-dom";

import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

import { Button, Modal, message, Tooltip, Input, InputNumber, Popover } from "antd";
import {
  DeleteOutlined,
  CheckOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { SavingsInvoice } from "../Components/SavingsInvoice/SavingsInvoice";
import { AlertRulesBoxModal } from "../Components/AlertRules/AlertRulesBox";
import CustomerComments from "../Components/CustomerComments";
import { InvoiceTableSendEmailModal } from "../Components/InvoiceTable/InvoiceTableSendEmail";

// TODO when adding a new row, the UI updates before it removes the new row

const BASE_TABLE_THEME = {
  BaseRow: `
    font-size: 12px;
    font-size: 12px;
    font-size: 12px;
    `,
  BaseCell: `
    white-space: unset;
    `,
  HeaderCell: `
    white-space: unset;
    `,
  Row: `
    background-color: #20262e;
    color: white;

    &:not(:last-of-type) > .td {
        border-bottom: 1px solid #20262e;
    }

    .td {
        border-top: 1px solid #20262e;
    }

    &:hover {
        color: white;
    }
    `,
  HeaderRow: `
    background-color: #20262e;
    `,
};

const SetApprovedAmountModal = ({ open, onCancel, onSave, defaultValue, overrideAmount }) => {
  const [approvedAmount, setApprovedAmount] = useState(0.00);
  const [rawInput, setRawInput] = useState("");

  const handleSetApprovedAmount = (value) => {
    console.log("value ", value)
    setRawInput(String(value))
    if (value === undefined || value === null || value === "") {
      setApprovedAmount(0.00)
    }

    setApprovedAmount(value);
  }

  useEffect(() => {
    if (overrideAmount) {
      setApprovedAmount(overrideAmount);
    }
  }, [overrideAmount]);
  
  const handleOnSave = (value) => {
      if (rawInput.match(/[^0-9.]/g)) {
        message.error("Please enter a valid amount");
        return;
      }

      // validate the value
      if (value === undefined || value === null || value === "") {
        message.error("Please enter a valid amount");
        return;
      }

      if (value < 0) {
        message.error("The amount cannot be negative");
        return;
      }

      // try parsing value into a float
      try {
        const parsedValue = parseFloat(value).toFixed(2);

        if (isNaN(parsedValue)) {
          message.error("Please enter a valid amount");
          return;
        }

        onSave(String(parsedValue));
      } catch (error) {
        message.error("Please enter a valid amount");
        return;
      }

  }

  const formatter = (value, info, decimals = false) => {
    if (value === undefined || value === null || value === "") {
      return "-";
    }

    if (decimals) {
      return parseFloat(value).toFixed(2);
    }

    return value;
  };

  const ApprovedAmountMessage = () => {
    if (defaultValue === null || defaultValue === undefined) {
      return <div>Failed to detect the proper approved amount.</div>;
    }
    
    return <div>The calculated Approved Amount is: {defaultValue} </div>
  }

  return (
    <>
    <Modal
      title="Set Approved Amount"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={400}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        {ApprovedAmountMessage()}
        <div> If you want to override this amount, enter a new amount below. </div>
        <InputNumber
          value={approvedAmount}
          onChange={(value) => handleSetApprovedAmount(value)}
          onPressEnter={(e) => console.log(e)}
          formatter={formatter}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          keyboard={false}
          controls={false}
          size="small"
        />
        <div>Note: This will only take effect for this session. Refreshing the page will lose the override</div>
        <Button
          type="primary"
          onClick={() => {
            handleOnSave(approvedAmount);
          }}
        >
          Set
        </Button>
      </div>
    </Modal>
    </>
  )
}




const DownloadHaulerInvoicePDF = ({ job_id }) => {
  const getPresignedURLToOpen = () => {
    axios
      .get(`/documents/${job_id}/getpresignedurl`)
      .then((res) => {
        let url = res.data.url;
        console.log("url ", url);
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.toString());
      });
  };
  return (
    <Button
      id="Download PDF"
      type="primary"
      style={{
        height: "30px",
        fontSize: "1rem",
      }}
      onClick={getPresignedURLToOpen}
    >
      Download
    </Button>
  );
};

const DownloadStampedHaulerInvoicePDF = ({
  tableData,
  job_id,
  metadata,
  customerName,
  haulerName,
  onComplete,
}) => {
  const [loadingStampedPDF, setLoadingStampedPDF] = useState(false);
  const [chargeAmount, setChargeAmount] = useState(null);
  const [overrideAmount, setOverrideAmount] = useState(null);
  const [foundAdjustments, setFoundAdjustments] = useState(false);
  const [adjustmentsAmount, setAdjustmentsAmount] = useState("0.00");
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openApprovedAmountModal, setOpenApprovedAmountModal] = useState(false);
  const [finalStampAmount, setFinalStampAmount] = useState(null);

  /**
   * finds the "charge the customer needs to pay" from the table data
   *
   * currently, we know a few variations that we need to match
   *
   * @returns {string | undefined | null}
   */
  const findChargeAmountForStamp = (metadata) => {
    const result = [];
    let adjustments = "0.00";

    // Get the charge amount from tableData
    const targetPhrases = [
      "current invoice charges",
      "current charges and fees",
      "total current charges",
      "total invoice charges",
      "total this invoice",        // nitti
      "current charges",           // aspen
      "site total",                // gfl
    ];

    Object.keys(tableData).forEach((key) => {
      const item = tableData[key];
      const values = Object.values(item);
      values.forEach((value) => {
        if (value) {
          const description =
            value.Description?.toLowerCase() ||
            value.Description_1?.toLowerCase();
          if (description) {
            /* some () - Method used to check if any of the target phrases are included in the description 
            includes(): Method used to check if a phrase is a subset of the description */ 
            const match = targetPhrases.some((phrase) => description.includes(phrase));
            if (match) {
              const amount = value.Amount;
              const extractedDescription =
                value.Description || value.Description_1;
              result.push({ amount, description: extractedDescription });
            }
          }
        }
      });
    });

    // Get the adjustments value from metadata
    // TODO this only handles cases from Waste Management
    if (metadata && metadata?.adjustments) {
      adjustments = metadata.adjustments;
      setAdjustmentsAmount(adjustments);
      setFoundAdjustments(true);
    }

    if (result.length > 0) {
      const adjustmentValue = parseFloat(adjustments);
      const chargeAmount = parseFloat(result[0]?.amount || 0);
      const finalChargeAmount = chargeAmount + adjustmentValue;
      console.log("Final Amount:", finalChargeAmount);
      return finalChargeAmount.toString();
    }

    return null;
  };

  useEffect(() => {
    setChargeAmount(findChargeAmountForStamp(metadata));
  }, [tableData, metadata]);

  const handleDownloadStampedPdfButton = () => {
    setLoadingStampedPDF(true);
    axios
      .post(`/generate_savings/presigned`, {
        job_id: job_id,
        amount: finalStampAmount, // Use the final charge amount here
      })
      .then((res) => {
        let url = res.data.url;
        setLoadingStampedPDF(false);
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.toString());
      });
  };

  useEffect(() => {
    if (overrideAmount) {
      setFinalStampAmount(overrideAmount);
    } else {
      setFinalStampAmount(chargeAmount);
    }
  }, [overrideAmount, chargeAmount]);

  /**
   * This function should return the tooltip text based on the charge amount
   * @returns {string}
   */
  const handleToolTip = () => {
    if (overrideAmount) {
      return `The charge amount is $${overrideAmount} (overridden)`;
    }

    if (chargeAmount === null || chargeAmount === 0) {
      return "We could not determine the charge amount. Please check the invoice data.";
    }

    if (foundAdjustments) {
      return `The charge amount is $${chargeAmount} (including adjustments)`;
    }

    return `The charge amount is $${chargeAmount} (including adjustments)`;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "5px",
        }}
      >
        <Tooltip title={handleToolTip()}>
          <Button
            type="primary"
            disabled={finalStampAmount === null}
            onClick={() => setOpenEmailModal(true)}
            style={{ marginLeft: "2rem", fontSize: "1rem", height: "30px" }}
          >
            Send Stamped PDF via Email
          </Button>
        </Tooltip>
        <Tooltip title={handleToolTip()}>
          <Button
            loading={loadingStampedPDF}
            type="primary"
            disabled={finalStampAmount === null}
            onClick={() => handleDownloadStampedPdfButton()}
            style={{ fontSize: "1rem", height: "30px" }}
          >
            Download Stamped PDF
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={() => setOpenApprovedAmountModal(true)}
          style={{ fontSize: "1rem", height: "30px" }}
        >
          Approved Amount
        </Button>
      </div>
      <InvoiceTableSendEmailModal
        open={openEmailModal}
        onCancel={() => {
          setOpenEmailModal(false);
        }}
        customerName={customerName}
        haulerName={haulerName}
        jobId={job_id}
        amount={finalStampAmount}
      />
      <SetApprovedAmountModal
        open={openApprovedAmountModal}
        overrideAmount={overrideAmount}
        defaultValue={chargeAmount}
        onCancel={() => {
          setOpenApprovedAmountModal(false);
        }}
        onSave={(amount) => {
          setOpenApprovedAmountModal(false);
          setOverrideAmount(amount);
        }}
        jobId={job_id}
      />
    </>
  );
};

// Sub component for the invoice table//
/**
 * Renders the buttons to manipulate a row in the table
 */
const RowActionButtons = ({ rowId, editing, onDeleteRow }) => {
  return (
    <>
      {editing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            columnGap: "5px",
            cursor: "pointer",
            padding: "1px 6px",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          <Button
            size="small"
            type="primary"
            danger={true}
            icon={<DeleteOutlined />}
            onClick={() => onDeleteRow(rowId)}
          ></Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const EditableCell = ({
  rowId,
  columnName,
  value,
  handleCellChange,
  resetSignal,
  canEdit,
}) => {
  const [initalValue, _] = useState(value);
  const [currentValue, setCurrentValue] = useState(value);

  const [isSelected, setIsSelected] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const handleMouseClick = () => {
    if (!canEdit) return;

    setIsSelected((prev) => !prev);

    if (isSelected) {
      setHasSelected(true);
    }
  };

  // when single changes, we will load the initial value
  useEffect(() => {
    setCurrentValue(initalValue);
  }, [resetSignal]);

  useEffect(() => {
    if (hasSelected && !isSelected) {
      if (currentValue !== initalValue) {
        handleCellChange(rowId, columnName, currentValue);
        setHasEdit(true);
      }
    }
  }, [isSelected]);

  useEffect(() => {
    if (currentValue === "" || !currentValue) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [currentValue]);

  return (
    <div style={{ outline: "none", whiteSpace: "unset" }}>
      {isSelected ? (
        <>
          <Popover
            content={
              <Button
                size="small"
                type="primary"
                icon={<CheckOutlined />}
                onClick={handleMouseClick}
              />
            }
            trigger="click"
            placement="right"
            arrow={false}
            open={isSelected}
          >
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              style={{
                width: "100%",
                fontSize: "11px",
                color: "white",
                background: " rgba(0,0,0,0.5)",
              }}
              variant="borderless"
              defaultValue={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
            />
          </Popover>
        </>
      ) : (
        <div
          style={{ width: "100%", height: "20px" }}
          onClick={(e) => handleMouseClick()}
        >
          <span
            style={hasEdit ? { backgroundColor: "yellow", color: "black" } : {}}
          >
            {isEmpty ? null : currentValue}
          </span>
        </div>
      )}
    </div>
  );
};

const TrackedChangesTooltip = ({
  violationMessage,
  previousValue,
  currentValue,
}) => {
  return (
    <Tooltip
      title={`${violationMessage} from ${previousValue} to ${currentValue}`}
    >
      <ExclamationCircleFilled style={{ color: "red", fontSize: "16px" }} />
    </Tooltip>
  );
};

const RowAlerts = ({ item, trackedChanges, showRowAlerts }) => {
  const renderTrackedChanges = () => {
    const trackedChange = trackedChanges.find((change) =>
      change.affectedRows.includes(item.id)
    );

    if (trackedChange && showRowAlerts) {
      return (
        <TrackedChangesTooltip
          violationMessage={trackedChange.violationMessage}
          previousValue={trackedChange.previousValue}
          currentValue={trackedChange.currentValue}
        />
      );
    }

    return null;
  };

  return renderTrackedChanges();
};

export default function InvoiceTable() {
  let { customerName, job_id, haulerName } = useParams();
  const [userComment, setUserComment] = useState("");

  const [theme, setTheme] = useState(useTheme([getTheme()]));
  const [data, setData] = useState({
    nodes: [],
  });
  const [columns, setColumns] = useState([]);
  const [editedTableValues, setEditedTableValues] = useState([]);
  const [editing, setEditing] = useState(false);

  const [metadataAnalysis, setMetadataAnalysis] = useState([]);
  const [metadataAnalysisColumns, setMetadataAnalysisColumns] = useState([]);

  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAlertRulesModalVisible, setIsAlertRulesModalVisible] =
    useState(false);

  const [loading, setLoading] = useState(true);
  const [metadataLoading, setMetadataLoading] = useState(true);
  const [unlockLoadingScreen, setUnlockLoadingScreen] = useState(false);

  // signal
  const [resetSignal, setResetSignal] = useState(0);

  // use as a marker to allow the component to recall the get table api
  const [resetApiMarker, setResetApiMarker] = useState(-1);

  //tracked
  const [trackedChanges, setTrackedChanges] = useState([]);

  //row alerts
  const [showRowAlerts, setShowRowAlerts] = useState(false);
  const [buttonText, setButtonText] = useState("Resolve");
  const [hasReadUpdated, setHasReadUpdated] = useState(false);

  const handleToggleRowAlerts = () => {
    // If hasUpdatedHasRead is true, just toggle showRowAlerts
    if (hasReadUpdated) {
      setShowRowAlerts(!showRowAlerts);
      setButtonText((prevText) =>
        prevText === "Resolve" ? "View Violations" : "Resolve"
      );
      return;
    }

    // Toggle the showRowAlerts state
    setShowRowAlerts(!showRowAlerts);
    setButtonText((prevText) =>
      prevText === "Resolve" ? "View Violations" : "Resolve"
    );

    // If buttonText is 'View Violations', don't make API calls, just set showRowAlerts to true
    if (buttonText === "View Violations") {
      setShowRowAlerts(!showRowAlerts);
      return;
    }

    // If showRowAlerts becomes true, update hasRead to true for all violations
    if (!showRowAlerts) {
      const updatedViolations = trackedChanges.map((change) => ({
        id: change.id,
        hasRead: true,
      }));

      // Send a request to update hasRead status to true for all violations
      axios
        .post(`/documents/${job_id}/violations`, updatedViolations)
        .then((response) => {
          console.log(response.data.message); // Log success message
          setHasReadUpdated(true);
        })
        .catch((error) => {
          console.error("Error updating hasRead status:", error);
        });
    } else if (showRowAlerts) {
      console.log("showing alerts");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddRow = () => {
    // Find the highest id in the existing data
    const highestId = data.nodes.reduce(
      (max, row) => (row.id > max ? row.id : max),
      0
    );
    // Create a new row object with empty values and the new id as the first property
    const newRow = { id: highestId + 1 }; // Generate a new id by incrementing the highest existing id
    columns.forEach((col) => (newRow[col.label] = "")); // Add the other properties with empty values
    // Add the new row to the data
    setData((prevData) => ({
      ...prevData,
      nodes: [...prevData.nodes, newRow],
    }));
  };

  // Function to prepare the data to be sent to the backend
  const handleConfirmClick = () => {
    let updatedNodes = [...data.nodes]; // Create a copy of the existing data

    // Update the existing rows with edited values
    updatedNodes = updatedNodes.map((node) => {
      const editedRowValues = editedTableValues.filter(
        (item) => item.rowId === node.id
      );
      if (editedRowValues.length > 0) {
        editedRowValues.forEach((item) => {
          node[item.columnName] = item.newValue;
        });
      }
      return node;
    });

    // Add the new row (if any) to the updated data
    const newRows = data.nodes.filter(
      (row) => !updatedNodes.some((updatedRow) => updatedRow.id === row.id)
    );
    updatedNodes = [...updatedNodes, ...newRows];

    // Update the state variable to make the edited values visible on the table insted of a blank row
    setEditedTableValues([]);
    setResetSignal((prev) => prev + 1);
    setLoading(true);

    // Send the updated data to the backend
    axios
      .post(`/documents/${job_id}/tabledata`, {
        columns: data.columnNames,
        data: updatedNodes,
      })
      .then((response) => {
        console.log("Data saved successfully!", response.body);
        // After successful save, send reset signal
        setResetSignal((prev) => prev + 1);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleCancelChanges = () => {
    setEditedTableValues([]);
    setResetSignal((prev) => prev + 1);
    setLoading(true);
  };

  const handleDeleteRow = (rowId) => {
    setData((prevData) => ({
      ...prevData,
      nodes: prevData.nodes.filter((row) => row.id !== rowId),
    }));
  };

  const handleCellChange = (rowId, columnName, newValue) => {
    console.log(
      `Edited row ${rowId}, column ${columnName}, new value: ${newValue}`
    );

    setEditedTableValues((prevValues) => {
      const existingIndex = prevValues.findIndex(
        (item) => item.rowId === rowId && item.columnName === columnName
      );

      if (existingIndex !== -1) {
        // If the value already exists, update it
        const updatedValues = [...prevValues];
        updatedValues[existingIndex].newValue = newValue;
        return updatedValues;
      } else {
        // If the value doesn't exist, add it to the array
        return [...prevValues, { rowId, columnName, newValue }];
      }
    });
  };

  const handleStartEdit = () => {
    setEditing(true);
    message.info("You are now in edit mode. Click on a cell to edit it.");
  };

  useEffect(() => {
    // Fetch comments for the specific job_id
    if (job_id) {
      axios
        .get(`/documents/${job_id}/get_comment`)
        .then((response) => {
          // Assuming the comment is stored in response.data.user_comment
          setUserComment(response.data.user_comment);
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
        });
    }
  }, [job_id]);

  useEffect(() => {
    if (editedTableValues.length > 0) {
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [editedTableValues]);

  // Fetch the table data from the backend
  useEffect(() => {
    const fetchHaulerInvoiceTableData = async () => {
      setLoading(true);
      const response = await axios.get(`/documents/${job_id}/tabledata`);

      setData({
        nodes: response.data.data,
        columnNames: response.data.columns,
        trackedChanges: response.data.tracked,
      });
      setTrackedChanges(response.data.tracked);

      // Check if hasRead is true in any of the trackedChanges
      const hasReadTrue = response.data.tracked.some(
        (change) => change.hasRead
      );

      // Set the initial state of showRowAlerts based on hasRead
      setShowRowAlerts(hasReadTrue);

      // Check if any of the trackedChanges have hasRead as true, then set button text accordingly
      const buttonText = hasReadTrue ? "View Violations" : "Resolve";
      setButtonText(buttonText);

      // Set loading to false after fetching data
      setLoading(false);
    };

    if (resetApiMarker != resetSignal) {
      fetchHaulerInvoiceTableData();
      setResetApiMarker(resetSignal);
    }
  }, [job_id, resetSignal]);

  // TODO this needs to be tested in repeated usage
  // re-render the table when the data changes
  useEffect(() => {
    if (resetApiMarker === -1) return;

    if (data.nodes.length === 0) return; // needed because now this gets triggered if you back navigate this page

    let columnRenders = data.columnNames.map((columnName) => {
      const isAffectedColumn = columnName === columnName;
      return {
        label: columnName,
        renderCell: (row) => {
          if (isAffectedColumn) {
            const trackedChange = trackedChanges.find(
              (change) =>
                change.affectedRows.includes(row.id) &&
                change.affectedColumn.includes(columnName)
            );

            if (!showRowAlerts && trackedChange && !editing) {
              return (
                <div>
                  <span>{row[columnName]}</span>
                  <span style={{ color: "red", marginLeft: "0.5rem" }}>
                    ({trackedChange.previousValue})
                  </span>
                </div>
              );
            }
          }
          return (
            <EditableCell
              rowId={row.id}
              columnName={columnName}
              value={row[columnName]}
              handleCellChange={handleCellChange}
              resetSignal={resetSignal}
              canEdit={editing}
            />
          );
        },
        resize: true,
      };
    });

    let gridTemplateColumns = columnRenders.map(() => "1fr").join(" ");

    // Adding utility column
    columnRenders.push({
      label: "",
      renderCell: (item) => (
        <>
          {editing && (
            <RowActionButtons
              rowId={item.id}
              editing={editing}
              onDeleteRow={handleDeleteRow}
            />
          )}
          {!editing && (
            <RowAlerts
              item={item}
              trackedChanges={trackedChanges}
              showRowAlerts={!showRowAlerts}
            />
          )}
        </>
      ),
    });

    gridTemplateColumns += " 5em";

    setTheme(
      useTheme([
        getTheme(),
        {
          Table: `
            background-color: #20262e;
            --data-table-library_grid-template-columns: ${gridTemplateColumns};
          `,
          ...BASE_TABLE_THEME,
        },
      ])
    );
    setColumns(columnRenders);
  }, [data, editing, resetSignal, trackedChanges, showRowAlerts]);

  useEffect(() => {
    if (!loading) {
      axios.get(`/documents/${job_id}/get_meta_analysis`).then((res) => {
        let response_data = res.data;
        console.log("metadata analysis recieved ", res.data);

        setMetadataAnalysisColumns(response_data.columns);
        setMetadataAnalysis(response_data.data);
        setMetadataLoading(false);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && !metadataLoading) {
      setUnlockLoadingScreen(true);
    } else {
      setUnlockLoadingScreen(false);
    }
  }, [loading, metadataLoading]);

  const handleAlertRulesModalClose = () => {
    setIsAlertRulesModalVisible(false);
  };

  const handleAlertRulesModalOpen = () => {
    setIsAlertRulesModalVisible(true);
  };

  // TODO yeah shravya, there needs to be a better way to tie in all of the loading
  // TODO this will spiral out of control really quickly
  // This is to trigger a reload of the table and metadata
  // when the job_id or customerName changes
  // The useEffect that loads in the table data is specifically blocked by Reset Signal
  useEffect(() => {
    if (resetApiMarker === -1) return;

    setLoading(true);
    setMetadataLoading(true);
    setResetSignal((prev) => prev + 1);
  }, [customerName, job_id]);

  return (
    <>
      {!unlockLoadingScreen ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
            }}
          >
            <MetaAnalysisTable
              job_id={job_id}
              data={metadataAnalysis}
              columns={metadataAnalysisColumns}
            />
          </div>
          {/* //Customer Comments Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <CustomerComments
              customerName={customerName}
              haulerName={haulerName}
            />
          </div>

          <div
            className="invoice-title-container"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingLeft: "2rem",
                textAlign: "left",
                columnGap: "0.2em",
              }}
            >
              <Button type="primary" onClick={showModal}>
                See Savings Invoice
              </Button>

              <Button type="primary" onClick={handleAlertRulesModalOpen}>
                Alert Rules
              </Button>
            </div>
            <div style={{ marginLeft: "auto" }} />
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: "normal",
              }}
            >
              {`${customerName}`}
            </div>
            <div style={{ marginLeft: "auto" }} />
            <div>
              {trackedChanges.length > 0 && (
                <Button
                  onClick={handleToggleRowAlerts}
                  style={{
                    color: "white",
                    backgroundColor: `${
                      buttonText === "Resolve" ? "green" : "gray"
                    }`,
                  }}
                >
                  {buttonText}
                </Button>
              )}
            </div>
            <div
              style={{
                paddingRight: "2rem",
                display: "flex",
                flexDirection: "row",
                columnGap: "5px",
              }}
            >
              <DownloadStampedHaulerInvoicePDF
                tableData={data}
                job_id={job_id}
                metadata={
                  metadataAnalysis?.length > 0 ? metadataAnalysis[0] : {}
                }
                customerName={customerName}
                haulerName={haulerName}
              />
              <DownloadHaulerInvoicePDF job_id={job_id} />
            </div>
          </div>

          <div
            className="tips-table-style-2"
            style={{
              paddingTop: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <CompactTable
              columns={columns}
              data={data}
              theme={theme}
              layout={{ custom: true }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            {editing ? (
              <>
                <Button
                  style={{
                    marginRight: "8px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                  onClick={handleAddRow}
                >
                  Add New Row +
                </Button>
                <Button
                  onClick={handleConfirmClick}
                  style={{
                    marginRight: "8px",
                    backgroundColor: "#0275d8",
                    color: "white",
                  }}
                >
                  Confirm
                </Button>
                <Button
                  onClick={handleCancelChanges}
                  style={{
                    marginRight: "8px",
                    backgroundColor: "#bb0a1e",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleStartEdit}
                  style={{
                    marginRight: "8px",
                    backgroundColor: "#bb0a1e",
                    color: "white",
                  }}
                >
                  Edit Invoice
                </Button>
              </>
            )}
          </div>

          <Modal
            title="Savings Invoice Settings"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={1100}
          >
            <SavingsInvoice
              invoiceData={data}
              invoiceColumns={columns}
              customerName={customerName}
              jobId={job_id}
            />
          </Modal>
          <AlertRulesBoxModal
            isOpen={isAlertRulesModalVisible}
            handleClose={handleAlertRulesModalClose}
            customerName={customerName}
            jobId={job_id}
            haulerName={haulerName}
            invoiceColumns={columns.map((col) => col.label)}
          />
        </>
      )}
    </>
  );
}
