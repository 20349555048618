import React from 'react';
import { PDFViewer, Page, Document, StyleSheet, Text, View, PDFDownloadLink } from '@react-pdf/renderer'


const COMPANY_INFORMATION = {
    companyName: 'Diversified Waste Solutions',
    companyAddress1: '321 Stevens Street, Ste. A',
    companyAddress2: 'Geneva, IL 60134',
    companyPhone: '1-888-397-6480',
    companyEmail: 'Invoices@DWSMGT.com',
  
    billTo: 'Dominium\ninvoices@dominiuminc.com',
    serviceAddress: 'Park Winds Apartments\n3604 East Douglas Avenue\nDes Moines, IA 50317',
    notes: 'Thank you for your business! Pleases remit payment to address on each invoice!',
  }
  

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 6,
  },
  companyInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    marginBottom: 20,
  },
  companyInfoItem: {
    fontSize: 10,
    textAlign: "left",
    marginBottom: 5,
  },
  tableContainer: {
    width: "100%",
  },
  header: {
    fontSize: 7,
    textAlign: "center",
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    height: 30,
    textAlign: "center",
    backgroundColor: "#f0f0f0",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // flexGrow: 1,
    alignItems: "center",
    width: "100%",
  },
  columnDescription: {
    width: "40%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "left",
  },
  columnQuantity: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "right",
  },
  columnBaseline: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "right",
  },
  columnApproved: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "right",
  },
  columnSavings: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "right",
  },
  columnDFees: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000",
    textAlign: "right",
  },
  columnDescriptionLast: {
    width: "40%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  columnQuantityLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right",
  },
  columnBaselineLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right",
  },
  columnApprovedLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right",
  },
  columnSavingsLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right",
  },
  columnDFeesLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontSize: 8,
    marginTop: 20,
  },
  columnDate: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "center",
  },
  columnDateLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "center"
  },
  columnTonnage: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    textAlign: "right",
  },
  columnTonnageLast: {
    width: "12%",
    padding: "5 5 5 5",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    textAlign: "right"
  },
});

const ReactPDFSavingsTable = ({data}) => {
    return (
        <View style={styles.tableContainer}>
            <ReactPDFSavingsTableHeader />
            {
                data.map((item, index) => {
                    if (index === data.length - 1) {
                        return <ReactPDFSavingsTableRowLast key={index} rowData={item} />
                    } else {
                        return <ReactPDFSavingsTableRow key={index} rowData={item} />
                    }
                })
            }
        </View>
    );
}

const ReactPDFSavingsTableRow = ({rowData}) => {
    return (
        <View style={styles.row}>
            <Text style={styles.columnDate}>{rowData.date !== null && rowData.date !== undefined ? rowData.date : '-'}</Text>
            <Text style={styles.columnDescription}>{rowData.description}</Text>
            <Text style={styles.columnQuantity}>{rowData.quantity !== null && rowData.quantity !== undefined ? rowData.quantity : '-'}</Text>
            <Text style={styles.columnTonnage}>{rowData.tonnage !== null && rowData.tonnage !== undefined ? rowData.tonnage : '-'}</Text>
            <Text style={styles.columnBaseline}>{parseFloat(rowData.baselineCost).toFixed(2)}</Text>
            <Text style={styles.columnApproved}>{parseFloat(rowData.approvedCost).toFixed(2)}</Text>
            <Text style={styles.columnSavings}>{parseFloat(rowData.savings).toFixed(2)}</Text>
            <Text style={styles.columnDFees}>{parseFloat(rowData.dFees).toFixed(2)}</Text>
        </View>
    );
}

const ReactPDFSavingsTableRowLast = ({rowData}) => {
    return (
        <View style={styles.row}>
            <Text style={styles.columnDateLast}>{rowData.date !== null && rowData.date !== undefined ? rowData.date : '-'}</Text>
            <Text style={styles.columnDescriptionLast}>{rowData.description}</Text>
            <Text style={styles.columnQuantityLast}>{rowData.quantity !== null && rowData.quantity !== undefined ? rowData.quantity : '-'}</Text>
            <Text style={styles.columnTonnageLast}>{rowData.tonnage !== null && rowData.tonnage !== undefined ? rowData.tonnage : '-'}</Text>
            <Text style={styles.columnBaselineLast}>{parseFloat(rowData.baselineCost).toFixed(2)}</Text>
            <Text style={styles.columnApprovedLast}>{parseFloat(rowData.approvedCost).toFixed(2)}</Text>
            <Text style={styles.columnSavingsLast}>{parseFloat(rowData.savings).toFixed(2)}</Text>
            <Text style={styles.columnDFeesLast}>{parseFloat(rowData.dFees).toFixed(2)}</Text>
        </View>
    );
}

const ReactPDFSavingsTableHeader = () => {
    return (
        <View style={[styles.row]}>
            <Text style={[styles.columnDate, styles.header]}>Date</Text>
            <Text style={[styles.columnDescription, styles.header]}>Description</Text>
            <Text style={[styles.columnQuantity, styles.header]}>Quantity</Text>
            <Text style={[styles.columnTonnage, styles.header]}>Tonnage</Text>
            <Text style={[styles.columnBaseline, styles.header]}>Baseline</Text>
            <Text style={[styles.columnApproved, styles.header]}>Approved Cost</Text>
            <Text style={[styles.columnSavings, styles.header]}>Savings</Text>
            <Text style={[styles.columnDFees, styles.header]}>DWS Fee</Text>
        </View>
    );
}

const ReactPDFSavingsTableFooter = () => {
    return (
        <View style={styles.footer}>
            <Text>
                {COMPANY_INFORMATION.notes}
            </Text>
        </View>
    )
}

const ReactPDFSavingsDWSInfo = () => {
    return (
        <View style={styles.companyInfo}>
            <Text style={styles.companyInfoItem}>{COMPANY_INFORMATION.companyName}</Text>
            <Text style={styles.companyInfoItem}>{COMPANY_INFORMATION.companyAddress1}</Text>
            <Text style={styles.companyInfoItem}>{COMPANY_INFORMATION.companyAddress2}</Text>
            <Text style={styles.companyInfoItem}>{COMPANY_INFORMATION.companyPhone}</Text>
            <Text style={styles.companyInfoItem}>{COMPANY_INFORMATION.companyEmail}</Text>
        </View>
    );
}

export const SavingsInvoicePDF = ({invoiceData, preview}) => {
    if (preview) {
        return (
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.flex}>
                            <ReactPDFSavingsDWSInfo />
                            <ReactPDFSavingsTable data={invoiceData} />
                            <ReactPDFSavingsTableFooter />
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        );
    } else {
        return (
            <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.flex}>
                    <ReactPDFSavingsDWSInfo />
                    <ReactPDFSavingsTable data={invoiceData} />
                    <ReactPDFSavingsTableFooter />
                </View>
            </Page>
        </Document>
        )
    }
}

// TODO need to somehow let the anchor tag expose it self to parent button
export const SavingsInvoicePDFDownload = ({invoiceData, pdfName}) => {
    return (
        <PDFDownloadLink document={<SavingsInvoicePDF invoiceData={invoiceData} preview={false} />} fileName={`${pdfName}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
        </PDFDownloadLink>
    );
}
