import React from 'react';
import { AlertRulesBox } from '../../Components/AlertRules/AlertRulesBox';
import { useAlertCreationFormData } from '../atoms';
import { useAtom } from "jotai";


export const AlertRulesTest = () => {
    const [alertCreationFormData, setAlertCreationFormData] = useAtom(useAlertCreationFormData);

    return (
        <div>
            <AlertRulesBox 
                customerName="SolsticeofMesa"
                haulerName="WasteManagement"
            />

            <div>
                {/* Show content of alertCreationFormData */}
                <pre>{JSON.stringify(alertCreationFormData, null, 2)}</pre>
            </div>
        </div>
    );
}