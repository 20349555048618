import React from "react";
import axios from "axios";

import MetadataTable from "../Components/MetadataTable";

import { useState, useEffect, useRef } from "react";

import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from '@table-library/react-table-library/baseline';


const BASELINE_THEME = {
    BaseRow: `
      font-size: 10px;
    `,
    HeaderRow: `
      text-transform: capitalize;
      background-color: #20262e;
    `,
    Row: `
    background-color: #20262e;
    color: white;

    &:not(:last-of-type) > .td {
        border-bottom: 1px solid #20262e;
      }
    
    .td {
        border-top: 1px solid #20262e;
    }

    &:hover {
        color: white;
    }
    `
}

export default function MetaAnalysisTable(props) {
    const data = props.data
    const columns = props.columns

    const [theme, setTheme] = useState(
        useTheme([
            getTheme(),
            BASELINE_THEME
        ])
    )

    let metadataTable = (
        <div>Meta comparison not found</div>
    )

    if (columns.length > 0) {
        let metaColumns = columns.map((column) => {

            if (column !== 's3_key') {
                return { label: column, renderCell: (item) => {return item[column];} }
            }
        })

        metaColumns.unshift({ label: '', renderCell: (item) => {
            let index_name = ''
            switch(item['id']) {
                case 0:
                    index_name = 'Current Invoice'
                    break;
                case 1:
                    index_name = 'Previous Invoice'
                    break;
                case 2:
                    index_name = 'Difference'
                    break;
                default:
                    break;
            }
            return index_name
        } })

        metaColumns = metaColumns.filter((column) => column !== undefined)

        const metadataNodes = {
            nodes: data
        }
        metadataTable = (
            <CompactTable columns={metaColumns} data={metadataNodes} theme={theme} />
        )
    }

    return (
        <div className="tips-table-style-3">
            {metadataTable}
        </div>
    )

}