import React from 'react';
import { useParams } from 'react-router-dom';
import YTable from './Dashboard';

const CustomerPage = () => {
  const { customerName, haulerName } = useParams();

  return (
    <div>
      {/* <h1>Customer Page</h1> */}
      {/* <p>Customer Name: {customerName}</p> */}
      <YTable customerName={customerName} haulerName={haulerName} />
      {/* <YTable/> */}
    </div>
  );
};

export default CustomerPage;
