import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Badge,
  Popover,
  Pagination,
  Divider,
  Switch,
} from "antd";
import {
  FileExclamationOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

// Styled components
const ResolvedItem = styled(List.Item)`
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #8E8E8E !important;
  background-color: #DEDEDE;
  border: 2px solid #B1B1B1 !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 2px solid #8E8E8E !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px #2f4f4f;
    transform: translateY(-2px);
  }
`;

const UnresolvedItem = styled(List.Item)`
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #f9dd79;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px #2f4f4f;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px #2f4f4f;
    transform: translateY(-2px);
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  width: 75%;
`;

const ItemHeader = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 1rem;
  flex-direction: row;
  width: 30%;
`;

const ItemMessage = styled.div`
  font-size: 12px;
  white-space: pre-line;
`;

const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  padding-bottom: 20px;
`;

const Notification = ({
  notification,
  handleNotificationClick,
  toggleRead,
  closePopover,
}) => {
  const {
    customer_name,
    display_customer_name,
    month_of_service,
    hauler_name,
    tracked,
    job_id,
    isRead,
  } = notification;

  const isResolved = isRead;

  const getCustomerName = () => {
    return display_customer_name || customer_name;
  };

  const getMessage = () => {
    if (month_of_service) {
      return `${month_of_service} has line items that have changed from the previous invoice.\nClick here to check it out.`;
    } else {
      return "There is a newly processed invoice that has line items that changed from previous months of service.\nClick here to check it out.";
    }
  };

  const handleClick = () => {
    handleNotificationClick(notification);
    toggleRead(job_id);
    closePopover(); // Call the closePopover function after markAsRead
  };

  const content = (
    <>
      <ItemHeader>
        <div>{hauler_name}</div>
        <div>{getCustomerName()}</div>
      </ItemHeader>
      <div style={{ textAlign: "center", width: "5%" }}>
        <Divider style={{ height: "40px" }} type="vertical" />
      </div>
      <ItemWrapper>
        {tracked.map((_, index) => (
          <ItemMessage key={index}>{getMessage()}</ItemMessage>
        ))}
      </ItemWrapper>
    </>
  );

  return isResolved ? (
    <ResolvedItem onClick={handleClick}>{content}</ResolvedItem>
  ) : (
    <UnresolvedItem onClick={handleClick}>{content}</UnresolvedItem>
  );
};

export const NotificationPopover = ({
  notifications,
  handleNotificationClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResolved, setShowResolved] = useState(false);
  const pageSize = 4; // Number of notifications per page

  useEffect(() => {
    const updatedNotifications = notifications.map((notification) => {
      // Determine if any tracked item has been read
      const hasReadTrackedItem = notification.tracked.some(
        (item) => item.hasRead
      );
      return {
        ...notification,
        isRead: hasReadTrackedItem, // Set isRead based on whether any tracked item hasread
      };
    });
    setNotificationList(updatedNotifications);
  }, [notifications]);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const toggleRead = (jobId) => {
    setNotificationList((prevNotificationList) =>
      prevNotificationList.map((notification) =>
        notification.job_id === jobId
          ? {
              ...notification,
              tracked: notification.tracked.map((item) =>
                item.hasRead === false ? { ...item, hasRead: true } : item
              ),
            }
          : notification
      )
    );
  };

  const toggleShowResolved = () => {
    setShowResolved((prev) => !prev);
    setCurrentPage(1)
  };

  // Reset the currentPage to 1 in Pagination whenever the popover is closed
  useEffect(() => {
    if (!isOpen) {
      setCurrentPage(1);
    }
  }, [isOpen]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredNotifications = showResolved
    ? notificationList
    : notificationList.filter((notification) => !notification.isRead);

  const totalNotifications = filteredNotifications.length;
  const startIndex = (currentPage - 1) * pageSize;
  const visibleNotifications = filteredNotifications.slice(
    startIndex,
    startIndex + pageSize
  );

  return (
    <Popover
      content={
        <>
          <PopoverHeader>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={showResolved}
                onChange={toggleShowResolved}
                checkedChildren={<CheckOutlined />} // icon checked state
                unCheckedChildren={<CloseOutlined />} // icon for unchecked state
              />
              <p style={{ margin: "0 0 0 8px" }}>Show resolved alerts</p>
            </div>
            <CloseCircleOutlined
              onClick={closePopover}
              style={{ cursor: "pointer", fontSize: "20px" }}
            />
          </PopoverHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <List
              dataSource={visibleNotifications}
              rowKey={(item) => item.job_id}
              renderItem={(item) => (
                <Notification
                  notification={item}
                  handleNotificationClick={handleNotificationClick}
                  toggleRead={toggleRead}
                  closePopover={closePopover}
                />
              )}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              current={currentPage}
              total={totalNotifications}
              pageSize={pageSize}
              onChange={handlePageChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" onClick={closePopover}>
              Exit
            </Button>
          </div>
        </>
      }
      trigger="click"
      open={isOpen}
      overlayStyle={{
        width: "600px"
      }}
    >
      <Badge
        count={
          notificationList.filter((notification) => !notification.isRead).length
        }
      >
        <FileExclamationOutlined
          style={{ fontSize: "24px" }}
          onClick={togglePopover}
        />
      </Badge>
    </Popover>
  );
};
