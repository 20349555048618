import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Logo from '../assets/images/sc-logo2.png'
import { NotificationPopover } from '../Pages/DocumentAlerts'
import axios from 'axios'

const Header = () => {
  let currentURL = window?.location?.href
  let currentActiveItem = 'Home'
  let path = window.location.pathname
  if (path == '/') {
    currentActiveItem = 'Home'
  } else if (path == '/upload_file') {
    currentActiveItem = 'UploadFile'
  }

  const [activeItem, setActiveItem] = useState(currentActiveItem)
  const history = useHistory();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('/customer-hauler-pair/violations');
        // Filter out notifications with tracked changes
        const filteredNotifications = response.data.filter(notification => notification.tracked.length > 0);
        setNotifications(filteredNotifications); // Update notifications state with filtered data
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotifications();
  }, []);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName)
  };

  const handleNotificationClick = (notification) => {
    const { customer_name: customerName, hauler_name: haulerName, job_id } = notification;
    const newUrl = `/customer/${customerName}/${haulerName}/invoice/${job_id}`;
    if (location.pathname !== newUrl) {
      history.push(newUrl);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div>
      <ul className="navbar-list">
        <li className={`navbar-item ${activeItem === 'Home' ? 'active' : ''}`}>
          <a href="/" className="navbar-link" onClick={() => handleItemClick('Home')}>
            Dashboard
          </a>
        </li>
        {/* Other navigation items */}
      </ul>
      <div className="navbar-item-right">
        {/* Pass notifications and click handler to NotificationPopover */}
        <NotificationPopover notifications={notifications} handleNotificationClick={handleNotificationClick} />
      </div>
    </nav>
  );
};

export default Header