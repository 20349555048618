import React, { useEffect, useState } from "react";
import { Input, InputNumber, Button, Spin, DatePicker, Popover } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import * as dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

const SavingsInvoiceTableSaveButton = ({ hasEdited, onClick }) => {
  return (
    <Button type="primary" onClick={onClick} disabled={!hasEdited}>
      Save
    </Button>
  );
};

const SavingsRowActionButtons = ({ rowId, onDeleteRow }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Button
        size="small"
        type="primary"
        danger
        icon={<DeleteOutlined />}
        onClick={() => onDeleteRow(rowId)}
      />
    </div>
  );
};

const SavingsConfirmButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <CheckOutlined
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{
        fontSize: isHovered ? "24px" : "20px",
        color: isHovered ? "#07c3fd" : "#A9A9A9",
        transition: "color 0.3s ease-in-out, font-size 0.3s ease-in-out",
      }}
    />
  );
};

const SavingsInvoiceTableCell = ({
  editMode,
  value,
  index,
  field,
  onSave,
  handleCellClick,
  format = "currency",
  isLastRow,
}) => {
  const [cellValue, setCellValue] = useState(value);
  const [isEdited, setIsEdited] = useState(false);

  const CELL_TYPE_FORMAT = {
    currency: "number",
    percent: "number",
    number: "number",
    text: "text",
    date: "date",
  };

  const cellType = CELL_TYPE_FORMAT[format];

  const handleCellChange = (e) => {
    if (cellType === "number") {
      setCellValue(e);
    } else {
      setCellValue(e.target.value);
    }
    setIsEdited(true);
  };

  const handleDateCellChange = (date) => {
    if (date == null) {
      setCellValue(undefined);
      return undefined;
    }

    setCellValue(date.format("YYYY-MM-DD"));
  };

  const handleDateValue = (value) => {
    if (value == null) {
      return undefined;
    }

    return dayjs(value);
  };

  const handleCellSave = () => {
    if (cellType === "number") {
      onSave(parseFloat(cellValue).toFixed(2), index, field);
    } else {
      onSave(cellValue, index, field);
    }

    setIsEdited(true);
  };

  /**
   * Object containing formatter functions for different types of values.
   * @type {Object.<string, Function(number): string>}
   */
  const FORMATTERS = {
    /**
     * Formats the value as currency.
     * @param {number | string | undefined | null} value - The value to format.
     * @returns {string} The formatted currency value.
     */
    currency: (value, info, decimal = false) => {
      if (
        value === undefined ||
        value === null ||
        value === "" ||
        value === "NaN"
      ) {
        return "-";
      }

      if (decimal) {
        return `$${parseFloat(value).toFixed(2)}`;
      }

      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    /**
     * Formats the value as a percentage.
     * @param {number | string | undefined | null} value - The value to format.
     * @returns {string} The formatted percentage value.
     */
    percent: (value) => {
      if (value === undefined || value === null || value === "") {
        return "-";
      }
      return `${parseFloat(value).toFixed(2)}%`;
    },
    /**
     * Formats the value as a number.
     * @param {number | string | undefined | null} value - The value to format.
     * @returns {string} The formatted number value.
     */
    number: (value, info, decimals = false) => {
      if (value === undefined || value === null || value === "") {
        return "-";
      }

      if (decimals) {
        return parseFloat(value).toFixed(2);
      }

      return value;
    },
    /**
     * Formats the value as text.
     * @param {string | undefined | null}
     * @returns {string} The formatted text value.
     * */
    text: (value) => {
      if (value === undefined || value === null) {
        return "-";
      }
      return value;
    },
    /**
     * Formats the value as date.
     * @param {string | undefined | null}
     * @returns {string} The formatted date value.
     * */
    date: (value) => {
      if (value === undefined || value === null) {
        return "-";
      }
      return value;
    },
  };

  const inputComponent = () => {
    if (cellType === "text") {
      return (
        <Input
          value={cellValue}
          onChange={handleCellChange}
          onPressEnter={handleCellSave}
          size="small"
        />
      );
    } else if (cellType === "date") {
      return (
        <DatePicker
          onChange={handleDateCellChange}
          value={handleDateValue(cellValue)}
        />
      );
    } else {
      return (
        <InputNumber
          value={cellValue}
          onChange={handleCellChange}
          onPressEnter={handleCellSave}
          formatter={FORMATTERS[format]}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          keyboard={false}
          controls={false}
          size="small"
        />
      );
    }
  };

  return (
    <td
      onClick={() => handleCellClick(index, field)}
      style={{ cursor: editMode ? "pointer" : "default" }}
    >
      {editMode && !isLastRow ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {inputComponent()}
          <SavingsConfirmButton onClick={handleCellSave} />
        </div>
      ) : isEdited ? (
        FORMATTERS[format](cellValue)
      ) : (
        FORMATTERS[format](value, null, format === "currency")
      )}
    </td>
  );
};

const SavingsInvoiceEditControlButtons = ({
  tableIsEditable,
  editMode,
  handleAddRowClick,
  handleCancelClick,
  handleEditClick,
}) => {
  return (
    <>
      {tableIsEditable && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: "55rem",
          }}
        >
          {editMode ? (
            <>
              <Button
                style={{
                  marginRight: "8px",
                  backgroundColor: "white",
                  color: "black",
                }}
                onClick={handleAddRowClick}
              >
                Add New Row +
              </Button>
              <Button
                onClick={handleCancelClick}
                style={{
                  marginRight: "8px",
                  backgroundColor: "#bb0a1e",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              id="editInvoiceButton"
              onClick={handleEditClick}
              style={{ backgroundColor: "#bb0a1e", color: "white" }}
            >
              Edit Invoice
            </Button>
          )}
        </div>
      )}
    </>
  );
};

/**
 * @param {Object} props - The properties.
 * @param {import("./SavingsInvoiceProcessing").SavingsLineItem[]} props.data
 * @param {boolean} props.editable - Indicates whether the table is editable or not.
 * @param {Function} props.onSave - The onSave event handler when user confirms the data changed.
 */
export const SavingsInvoiceTable = ({ data, editable, onSave }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const [currentData, setCurrentData] = useState(data);
  const [editingIndex, setEditingIndex] = useState({ index: -1, field: "" });
  const [hasEdited, setHasEdited] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      if (over.data.current.sortable.index === currentData.length - 1) {
        return;
      }

      setCurrentData((items) => {
        const oldIndex = items.map((o) => o.id).indexOf(active.id);
        const newIndex = items.map((o) => o.id).indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      setHasEdited(true);
    }
  };

  /**
   * When the cell is clicked, the cell becomes editable. Only if the table is editable.
   * @param {number} index
   */
  const handleCellClick = (index, field) => {
    if (editMode) {
      // Check if the current cell is already being edited
      // if so then do not set it again
      if (editingIndex.index === index && editingIndex.field === field) {
        return;
      }

      setEditingIndex({ index, field });
    }
  };

  /**
   * Handles the change of a cell in the SavingsInvoiceTable.
   *
   * @param {string} value - The event object representing the cell change.
   * @param {number} index - The index of the row in the table.
   * @param {string} field - The field name of the cell being changed.
   */
  const handleCellChange = (value, index, field) => {
    const newData = JSON.parse(JSON.stringify(currentData));
    newData[index][field] = value;

    // create new object to trigger re-render
    const newIndex = { index: -1, field: "" };

    setCurrentData(newData);
    setEditingIndex((prev) => {
      return newIndex;
    });
    setHasEdited(true);
  };

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  /**
   * Handles the saving of the data for the table
   * Sets the editing index to -1.
   */
  const calculateTotals = (data) => {
    return data.reduce(
      (acc, curr) => {
        // try to convert each value to a number, if it fails to parse, then set it to 0
        curr.baselineCost = parseFloat(curr.baselineCost) || 0;
        curr.approvedCost = parseFloat(curr.approvedCost) || 0;
        curr.savings = parseFloat(curr.savings) || 0;
        curr.dFees = parseFloat(curr.dFees) || 0;

        if (curr.description !== "Total") {
          acc.baselineCost += curr.baselineCost;
          acc.approvedCost += curr.approvedCost;
          acc.savings += curr.savings;
          acc.dFees += curr.dFees;
        }
        return acc;
      },
      {
        description: "Total",
        baselineCost: 0,
        approvedCost: 0,
        savings: 0,
        dFees: 0,
      }
    );
  };
  const handleCellSave = () => {
    if (onSave) {
      const totalRow = calculateTotals(currentData.slice(0, -1));
      const updatedData = [...currentData.slice(0, -1), totalRow];
      onSave(updatedData);
      setCurrentData(updatedData);
      setEditingIndex({ index: -1, field: "" });
      setHasEdited(false);
      setEditMode(false);
    } else {
      console.warning("onSave is not defined");
    }
  };

  /**
   * Handle adding new row to the table
   */
  const handleAddRow = () => {
    const newRow = {
      date: undefined,
      description: undefined,
      quantity: 0,
      baselineCost: 0,
      approvedCost: 0,
      savings: 0,
      dFees: 0,
      id: uuidv4(),
    };

    const totalRowIndex = currentData.findIndex(
      (row) => row.description === "Total"
    );
    // Insert the new row before the Total row
    setCurrentData((prevData) => {
      const newData = [
        ...prevData.slice(0, totalRowIndex),
        newRow,
        ...prevData.slice(totalRowIndex),
      ];
      const totalRow = calculateTotals(newData.slice(0, -1));
      return [...newData.slice(0, -1), totalRow];
    });
  };

  /**
   * Handle delete row from the table using the id
   */
  const handleDeleteRow = (id) => {
    setCurrentData((prev) => {
      const newData = [...prev];
      newData.splice(
        prev.findIndex((item) => item.id === id),
        1
      );
      return newData;
    });
    setHasEdited(true);
  };

  /**
   * Handle canceling the edit mode
   * Reset the current data to the original data
   */
  const handleCancelEdit = () => {
    setCurrentData(data);
    setEditMode(false);
    setHasEdited(false);
    setEditingIndex({ index: -1, field: "" });
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  // ? This is apparently bad practice but it makes the code a lot cleaner
  const TableComponent = () => {
    return (
      <div className="tips-table-style-4">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Tonnage</th>
              <th>Base Line</th>
              <th>Approved Cost</th>
              <th>Savings</th>
              <th>DWS Fee</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => {
              const id = item.id;

              return (
                <SavingsInvoiceSortableRow
                  key={id}
                  id={id}
                  index={index}
                  item={item}
                  editable={editable}
                  editingIndex={editingIndex}
                  handleCellClick={handleCellClick}
                  handleCellChange={handleCellChange}
                  handleDeleteRowClick={handleDeleteRow}
                  editMode={editMode}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {typeof data === "undefined" ? (
        <Spin />
      ) : (
        <div
          style={{
            flexDirection: "column",
            rowGap: "10px",
            display: "flex",
          }}
        >
          <SavingsInvoiceEditControlButtons
            tableIsEditable={editable}
            editMode={editMode}
            handleEditClick={toggleEditMode}
            handleAddRowClick={handleAddRow}
            handleCancelClick={handleCancelEdit}
          />
          {editable && editMode ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={currentData}
                strategy={verticalListSortingStrategy}
              >
                <TableComponent />
              </SortableContext>
            </DndContext>
          ) : (
            <SortableContext
              items={currentData}
              strategy={verticalListSortingStrategy}
            >
              <TableComponent />
            </SortableContext>
          )}

          {editable && editMode && (
            <SavingsInvoiceTableSaveButton
              hasEdited={hasEdited}
              onClick={() => handleCellSave()}
            />
          )}
        </div>
      )}
    </>
  );
};

const SavingsInvoiceSortableRow = ({
  id,
  index,
  item,
  editable,
  editingIndex,
  handleCellClick,
  handleCellChange,
  handleDeleteRowClick,
  editMode,
}) => {
  const isCurrentSelected = editingIndex.index === index; // Check if the current row is selected
  const isLastRow = item.description === "Total"; // Check if the current row is the last row

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    disabled: isLastRow || isCurrentSelected,
  });

  const style = {
    // transform: CSS.Transform.toString(transform),
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const COLUMN_MAPPING = {
    date: {
      format: "date",
      field: "date",
    },
    description: {
      format: "text",
      field: "description",
    },
    quantity: {
      format: "number",
      field: "quantity",
    },
    tonnage: {
      format: "number",
      field: "tonnage",
    },
    baselineCost: {
      format: "currency",
      field: "baselineCost",
    },
    approvedCost: {
      format: "currency",
      field: "approvedCost",
    },
    savings: {
      format: "currency",
      field: "savings",
    },
    dFees: {
      format: "currency",
      field: "dFees",
    },
  };

  const loadSavingsRowTools = () => {
    if (!editMode || isLastRow) {
      return "";
    }

    return (
      <SavingsRowActionButtons rowId={id} onDeleteRow={handleDeleteRowClick} />
    );
  };

  return (
    <Popover content={loadSavingsRowTools()} arrow={false} placement="right">
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {Object.keys(COLUMN_MAPPING).map((column) => (
          <SavingsInvoiceTableCell
            key={column}
            editMode={
              editable &&
              editingIndex.index === index &&
              editingIndex.field === column
            }
            value={item[column]}
            index={index}
            field={COLUMN_MAPPING[column].field}
            onSave={handleCellChange}
            handleCellClick={handleCellClick}
            format={COLUMN_MAPPING[column].format}
            isLastRow={isLastRow}
          />
        ))}
      </tr>
    </Popover>
  );
};
