import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Card, Typography } from 'antd';
import Loader from '../Components/Loader';

const HaulersPage = () => {
  const { customerName } = useParams();
  const history = useHistory();
  const [haulersData, setHaulersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHaulersData = async () => {
      try {
        const response = await axios.get(`/customer-hauler-pair?customer_name=${customerName}`);
        setHaulersData(response.data || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching haulers data:', error);
        setIsLoading(false);
      }
    };

    fetchHaulersData();
  }, [customerName]);

  // Redirect to dashboard page with selected customer and hauler information
  const handleHaulerClick = (haulerName) => {
    const encodedCustomerName = encodeURIComponent(customerName);
    const encodedHaulerName = encodeURIComponent(haulerName);
    history.push(`/dashboard?customer=${encodedCustomerName}&hauler=${encodedHaulerName}`);
  };

  const filteredHaulersData = haulersData.filter(hauler => hauler.customer_name === customerName);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
      <div style={{ margin: '1rem auto', textAlign: 'center', backgroundColor: 'black', padding: '1px', borderRadius: '5px', maxWidth: '300px'}}>
      <Typography style={{ color: 'skyblue' }}>
        <b>{`${customerName}`}</b>
      </Typography>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          {filteredHaulersData.map((hauler, index) => (
            <Card
              key={index}
              onClick={() => handleHaulerClick(hauler.hauler_name)} 
              style={{ width: 300, height: 200, margin: '8px', backgroundColor: '#e6e6fa', borderColor: '#2f4f4f' }}
              hoverable
            >
              <p>{hauler.hauler_name}</p>
              {/* Add other hauler details as needed */}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default HaulersPage;