import axios from "axios";

export async function getCustomerEmail(customerName, haulerName) {
  try {
    const response = await axios.get(
      `/customer-hauler-pair/email?customerName=${customerName}&haulerName=${haulerName}`
    );

    const parsedRes = {
      email: response.data?.email,
      cc_emails: response.data?.cc_emails ? response.data.cc_emails.split(",") : [],
    };

    return parsedRes;
  } catch (error) {
    console.error("Error fetching alert rules:", error);
    return null;
  }
}

export async function updateCustomerEmail(customerName, haulerName, email, ccEmails=null) {
  // if ccEmails is an array, give me a comma separated string of emails, otherwise give me an empty string
  const cc_emails = ccEmails ? ccEmails.join(",") : "";


  try {
    await axios.post(
      `/customer-hauler-pair/email?customerName=${customerName}&haulerName=${haulerName}`,
      {
        email,
        cc_emails
      }
    );
    return true;
  } catch (error) {
    console.error("Error updating customer email:", error);
    return false;
  }
}

export async function sendEmail(email, jobId, amount, ccEmails=null) {
  const cc_emails = ccEmails ? ccEmails.join(",") : "";
  try {
    await axios.post(`/generate_savings/email`, {
      job_id: jobId,
      recipient: email,
      cc_emails,
      amount,
    });
    return true;
  } catch (error) {
    console.error("Error sending email:", error);
    return false;
  }
}
